import React, {useEffect, useState} from 'react';
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import DataTable from "../../../../components/dataTable/DataTable";
import {Toolbar} from "../components";
import {contractStatuses} from "../components";
import {connect} from "react-redux";
import {actions} from "../../actions";
import {actions as commonActions} from "../../../commonReduxActions/actions";
import {Button, Form, Spinner} from "react-bootstrap";
import Badge from '@material-ui/core/Badge'
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import {makeStyles} from "@material-ui/styles";
import {DataTableContainer} from '../../utils'
import {LoadingSpinner, SolInput} from '../../../../components';
import {useSubheader} from "../../../../../_metronic/layout";
import {withRoles} from "../../../../router/SecuredRoute";
import Select from "react-select";
import moment from "moment";
import {toast} from "react-toastify";
import {exportInExcel} from "../../../../utils/excelExport";
import {isNullorUndefined} from "../../../../utils/utilityFunctions";
import { parseParams} from "../../../../utils/utilityFunctions";
import {Controller, useForm} from "react-hook-form";
import {checkAuthorization} from "../../../auth/authorization";
import {getServiceName} from "../../../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../../../utils/roleRelatedValues/actionNames";
import { calculateTimeRemaining } from '../../../../utils/dateFormat/DateTimeFormat'
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import {useMediaQuery} from "react-responsive";
import {showNotifications} from "../../../../utils/notification";
import {customStylesForMultiSelectField} from "../../../../utils/reactSelectRelated/customStylesForMultiSelect";
import {ValueContainer} from "../../../../utils/reactSelectRelated/showCustomizedValueLabelForMultiSelect";
import {Option} from "../../../../utils/reactSelectRelated/showOptionsWithCheckbox";
import {groupSelectedOptions} from "../../../../utils/reactSelectRelated/groupSelectedOptionsForMultiSelect";
import {formulateTextFromSelectedItems} from "../../../../utils/multiSelectValueFormation/formatForExportedFile";
import {formulateApiRequestDataFromSelectedItems} from "../../../../utils/multiSelectValueFormation/formatForApiRequest";
import {customStylesForSingleSelectField} from "../../../../utils/reactSelectRelated/customStylesForSingleSelect";
import {formatLargeCurrency} from "../../../../utils/formatLargeCurrency";
import {styled} from "@mui/material/styles";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";


const useStylesIconButton = makeStyles({
    root: {
        marginTop: '0',
        paddingTop: '4px',
    },
});

const Contracts = (props) => {
    const {register, errors, control, clearErrors, handleSubmit, setValue} = useForm();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const [showFilter, setShowFilter] = useState(true);
    const [urlIsEdited, setUrlIsEdited] = useState(false);
    const [userIsFromSolshare, setUserIsFromSolshare] = useState(false);

    const [smartBatteryFromUrl, setSmartBatteryFromUrl] = useState("");
    const [dongleFromUrl, setDongleFromUrl] = useState("");
    const [borrowers, setBorrowers] = useState([]);
    const [borrower, setBorrower] = useState("");
    const [borrowerFromUrl, setBorrowerFromUrl] = useState("");
    const [garages, setGarages] = useState([]);
    const [garage, setGarage] = useState("");
    const [garageFromUrl, setGarageFromUrl] = useState("");
    const [financiers, setFinanciers] = useState([]);
    const [financier, setFinancier] = useState("");
    const [financierFromUrl, setFinancierFromUrl] = useState("");
    const [contractIdFromUrl, setContractIdFromUrl] = useState("");
    const [statusFromUrl, setStatusFromUrl] = useState("");
    const [loans, setLoans] = useState([]);
    const [garagesForValidation, setGaragesForValidation] = useState(undefined);
    const subHeader = useSubheader();

    const [page, setPage] = useState(1);
    const [filterData, setFilterData] = useState({});
    const [language, setLanguage] = useState(null);
    const [queryParams, setQueryParams] = useState(null);
    const [initialfilterParam, setInitialfilterParam] = useState(null);
    const [filterParamForApiCall, setFilterParamForApiCall] = useState(null);

    // Summary section related
    const [totalContracts, setTotalContracts] = useState(0);
    const [numberOfActiveBorrowers, setNumberOfActiveBorrowers] = useState(0);
    const [numberOfActiveGarages, setNumberOfActiveGarages] = useState(0);
    const [numberOfActiveFinanciers, setNumberOfActiveFinanciers] = useState(0);
    const [totalContractAmount, setTotalContractAmount] = useState(0);
    const [totalPaidAmount, setTotalPaidAmount] = useState(0);
    const [showRepaymentEfficiencyTooltip, setShowRepaymentEfficiencyTooltip] = useState(false);
    const [userIsPaginating, setUserIsPaginating] = useState(false);
    const [showToolTipIcon, setShowToolTipIcon] = useState(false);

    const garageServiceName = getServiceName('garageService')
    const contractManagementFeatureName = getFeatureName('contractManagementFeature')
    const createActionName = getActionName('createAction')

    // Tooltip for "Responsible for Payment"
    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
    }));
    const [showTooltipForUnit, setShowTooltipForUnit] = useState(false);
    const toolTipTextForDocumentField = <>
        <span><strong>B </strong>= Billion (10⁹)</span><br/>
        <span><strong>T </strong>= Trillion (10¹²)</span><br/>
        <span><strong>Q </strong>= Quadrillion (10¹⁵)</span><br/>
        <span><strong>Qi </strong>= Quintillion (10¹⁸)</span><br/>
        <span><strong>Sx </strong>= Sextillion (10²¹)</span>
    </>
    const toolTipForUnit = () => {
        return <>
            <HtmlTooltip
                title={toolTipTextForDocumentField} placement="right" arrow componentsProps={{
                tooltip: {
                    sx: {
                        '& .MuiTooltip-arrow': {
                            color: 'common.white'
                        }
                    }
                }}} open={showTooltipForUnit}
                onOpen={() => setShowTooltipForUnit(true)}
                onClose={() => setShowTooltipForUnit(false)}>
                <InfoIcon size={'sm'} style={{color: '#F18D00'}} onClick={() => {
                    if (isTabletOrMobile) {
                        setShowTooltipForUnit(!showTooltipForUnit)
                    }
                }}/>
            </HtmlTooltip>
        </>
    }

    const pageTitle = 'Contract Management'

    const column1 = 'Smart Battery'
    const column2 = 'Borrower'
    const column3 = 'Financier'
    const column4 = 'Contract Date'
    const column5 = 'Investment'
    const column6 = 'Repayment'

    const validStatusKeys = contractStatuses.map(item => item.value)

    const loanListColumns =  [
        {
            field: 'device_serial_number',
            title: column1,
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData)=>{
                let vehicleType = ''
                if (rowData.ev_details) {
                    vehicleType = rowData.ev_details.ev_type.split("_").map((partOfName) => {
                        return partOfName.charAt(0).toUpperCase() + partOfName.slice(1)
                    }).join(' ')
                }
                return   <>
                    <h6 className={"text-nowrap"}><strong>SB # {rowData.device_serial_number}</strong></h6>
                    <span className={"text-nowrap"}>Dongle # {rowData.dongle_serial_number}</span><br/>
                    {rowData.device_details?.dongle_details? <><span className={"text-nowrap"}>FW v{rowData.device_details.dongle_details.firmware_version}, HW v{rowData.device_details.dongle_details.hardware_version}</span><br/></>:''}
                    <span className={"text-nowrap"}>Battery # {rowData.device_details.battery_serial_number}</span><br/>
                    {rowData.device_details?.battery_details? <>
                        <span className={"text-nowrap"}>
                            {vehicleType? <>{vehicleType}, {rowData.device_details.battery_details.battery_vendor}</>:
                                <>{rowData.device_details.battery_details.battery_vendor}</>}
                            {rowData.device_details?.battery_details?.reference_capacity?
                                <> {rowData.device_details.battery_details.nameplate_voltage}V, {rowData.device_details.battery_details.reference_capacity}Ah</>:
                                <> {rowData.device_details.battery_details.nameplate_voltage}V</>}
                        </span><br/>
                    </>:<>
                        {vehicleType? <>
                            <span className={"text-nowrap"}>{vehicleType}</span><br/>
                        </>:''}
                    </>}
                    <span className={"text-nowrap"}>Contract # {rowData.contract_id}</span>
                </>
            }
        },
        {
            field: 'borrowerName',
            title: column2,
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            disableClick: true,
            render: (rowData)=>{
                return   <>
                    <h6 className={"text-nowrap"}><strong>{rowData.borrowerName}</strong></h6>
                    <span className={"text-nowrap"}>{rowData.garageName}</span>
                </>
            }
        },
        {
            field: 'financierName',
            title: column3,
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            disableClick: true,
            render: (rowData)=>{
                return   <>
                    <h6 className={"text-nowrap"}><strong>{rowData.financierName}</strong></h6>
                    {rowData.financierAddress? <><span className={"text-nowrap"}>{rowData.financierAddress}</span></>:''}
                </>
            }
        },
        {
            field: 'effective_from',
            title: column4,
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData)=>{
                return   <>
                    <h6 className={"text-nowrap"}><strong>{moment(rowData.effective_from).format("MMM DD, YYYY")}</strong></h6>
                    {rowData.effective_till && !moment(rowData.effective_till, "YYYY-MM-DD").isSameOrBefore(moment())? <span className={"text-nowrap"}>{calculateTimeRemaining(rowData.effective_till)} Remaining</span>:''}
                </>
            }
        },
        {
            field: 'loan_amount',
            title: column5,
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData) => {
                let contractTypeText = ''
                if ((rowData.lease_type && rowData.lease_type === 'rent') || rowData.contract_type === 'rent') {
                    contractTypeText = rowData.prepaid_payment_enabled? 'Rent | Prepaid':'Rent | Postpaid'
                } else if ((rowData.lease_type && rowData.lease_type === 'loan') || rowData.contract_type === 'loan') {
                    contractTypeText = rowData.prepaid_payment_enabled? 'Loan | Prepaid':'Loan | Postpaid'
                } else {
                    // Sometimes lease_type holds 'null' but at that time contract_type is constantly holding 'lease' - Noor Reza, 8th December, 2024 11:20 pm
                    contractTypeText = rowData.prepaid_payment_enabled? 'Lease | Prepaid':'Lease | Postpaid'
                }
                return (
                    <>
                        <h6 className={"text-nowrap"}><strong>Tk {new Intl.NumberFormat('en-IN').format(rowData.loan_amount.toFixed(2))}</strong></h6>
                        {rowData.downpayment_amount? <><span className={"text-nowrap"}>Downpayment Tk {new Intl.NumberFormat('en-IN').format(rowData.downpayment_amount.toFixed(2))}</span><br/></>:''}
                        <span className={"text-nowrap"}>{contractTypeText}</span>
                    </>
                )
            }
        },
        {
            field: 'paid_amount',
            title: "Recovered Amount",
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData) => {
                let remainingAmount = rowData.loan_amount - rowData.paid_amount
                return (
                    <>
                        <h6 className={"text-nowrap"}><strong>Tk {new Intl.NumberFormat('en-IN').format(rowData.paid_amount.toFixed(2))}</strong></h6>
                        {remainingAmount? <><span className={"text-nowrap"}>Tk {new Intl.NumberFormat('en-IN').format(remainingAmount.toFixed(2))} Remaining</span></>:''}
                    </>
                )
            }
        },
        {
            field: 'loan_schedules',
            title: column6,
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData) => {
                // TODO: This can be taken within a function as it has been reused at the export data section also
                let repaymentText = 'N/A'
                if ((rowData.lease_type && rowData.lease_type === 'rent') || rowData.contract_type === 'rent') {
                    repaymentText = 'Tk ' + new Intl.NumberFormat('en-IN').format(rowData.per_rent_payable.toFixed(2)) + ' / Rent'
                } else {
                    if (rowData.lease_payment_interval === 'weekly') {
                        repaymentText = 'Tk ' +
                            new Intl.NumberFormat('en-IN').format(rowData.per_installment_payable.toFixed(2)) + ' / Week'
                    } else {
                        // Monthly lease
                        repaymentText = 'Tk ' +
                            new Intl.NumberFormat('en-IN').format(rowData.per_installment_payable.toFixed(2)) + ' / Month'
                    }
                }
                return (
                    <>
                        <h6 className={"text-nowrap"}><strong>{repaymentText}</strong></h6>
                        {rowData.dueActivityText? <><span className={"text-nowrap"}>{rowData.dueActivityText}</span></>:''}
                    </>
                )
            }
        },
        {
            field: 'status',
            title: "Status",
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData) => {
                if (validStatusKeys.includes(rowData.status)) {
                    let statusObj = contractStatuses.find(item => item.value === rowData.status)
                    return <>
                        <Badge style={statusObj['style']}>{statusObj['label']}</Badge>
                    </>
                } else {
                    return <>-</>
                }
            }
        }
    ]

    const {
        COLLECT_FINANCIERS,
        COLLECT_GARAGE_LIST,
        COLLECT_LOAN_LIST,
        COLLECT_LOAN_LIST_FOR_EXPORT,
        GET_GARAGE_LIST
    } = props;
    const classes = useStylesIconButton();

    useEffect(() => {
        setLanguage(props.language)
        setUserIsFromSolshare(props.isSolshareUser)
        subHeader.setBreadCrumbComponent(null)
        if (props.isSolshareUser) {
            COLLECT_FINANCIERS()
        }
        // Ensuring that page and status exists in the url
        if (!props.history.location.search) {
            props.history.push({
                search: 'page=1&status=active'
            })
            setValue('status', contractStatuses[1])
        } else {
            let searchParams = props.history.location.search
            let searchObj = parseParams(searchParams)
            if (!searchObj.hasOwnProperty('page')) {
                searchParams += "&page=1"
            }
            if (!searchObj.hasOwnProperty('status')) {
                searchParams += "&status=active"
                setValue('status', contractStatuses[1])
            }
            props.history.push({
                search: searchParams
            })
        }
        COLLECT_GARAGE_LIST()
        if (checkAuthorization(props.authorization, garageServiceName, contractManagementFeatureName, [createActionName])) {
            subHeader.setActionButtons(
                <>
                    <div className="d-flex align-item-center justify-content-end">
                        <Button variant="warning" size={'sm'} type="button" style={{
                            backgroundColor: '#F18D00 !important',
                        }} onClick={goToContractCreationPage} classes={classes} className={'ml-1'}>
                            <i className="fa fa-plus"/> Create Contract
                        </Button>
                    </div>
                </>
            )
        } else {
            subHeader.setActionButtons(null)
        }
        return ()=>{
            props.RETURN_TO_INITIAL_STATE();
            props.RETURN_TO_INITIAL_STATES_FOR_COMMON_REDUCER();
        }
    }, []);

    // ----- Begin: Section related to Loan Creation //
    const goToContractCreationPage = () => {
        props.history.push({
            pathname: '/contract-management/create'
        })
    }
    // ----- End: Section related to Loan Creation //

    // Saving latest garage information
    useEffect(() => {
        if (props.garagesForValidation && props.garagesForValidation.length > -1) {
            const garages = props.garagesForValidation;
            setGaragesForValidation(garages.map((garage) => {
                return {
                    ...garage
                }
            }))
        }
    }, [props.garagesForValidation])

    // ----- Begin: Section related to filter process ---- //
    const filterForm = () => {
        return <>
            <Form onSubmit={handleSubmit(filterTheList)}>
                <div className={'row g-3'}>
                    <div className={'col-md-3'}>
                        <Form.Group>
                            <div>
                                <Form.Label>Contract ID</Form.Label>
                            </div>
                            <SolInput
                                type={"text"}
                                name={"contract_id"}
                                placeholder={"Type Contract ID..."}
                                autoComplete={"off"}
                                ref={register({
                                    validate: {
                                        isNumber: value => (/^(0|[1-9][0-9]*)$/i.test(value) || !value) || "Invalid ID!"
                                    }
                                })}
                            />
                            {errors.contract_id && <div className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.contract_id.message}</div>}
                        </Form.Group>
                    </div>
                    <div className={'col-md-3'}>
                        <Form.Group>
                            <div>
                                <Form.Label>Smart Battery</Form.Label>
                            </div>
                            <SolInput
                                type={"text"}
                                name={"smart_battery"}
                                placeholder={"Type Smart Battery ID..."}
                                autoComplete={"off"}
                                ref={register({
                                    validate: {
                                        isNumber: value => (/^(0|[1-9][0-9]*)$/i.test(value) || !value) || "Invalid ID!"
                                    }
                                })}
                            />
                            {errors.smart_battery && <div className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.smart_battery.message}</div>}
                        </Form.Group>
                    </div>
                    <div className={'col-md-3'}>
                        <Form.Group>
                            <div>
                                <Form.Label>Dongle ID</Form.Label>
                            </div>
                            <SolInput
                                type={"text"}
                                name={"dongle"}
                                placeholder={"Type Dongle ID..."}
                                autoComplete={"off"}
                                ref={register({
                                    validate: {
                                        isNumber: value => (/^(0|[1-9][0-9]*)$/i.test(value) || !value) || "Invalid ID!"
                                    }
                                })}
                            />
                            {errors.dongle && <div className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.dongle.message}</div>}
                        </Form.Group>
                    </div>
                    <div className={'col-md-3'}>
                        <Form.Group>
                            <div>
                                <Form.Label>Contract Status</Form.Label>
                            </div>
                            <Controller
                                control={control}
                                name={"status"}
                                defaultValue={contractStatuses[1]}
                                render={( { onChange, onBlur, value, name, ref },
                                          { invalid, isTouched, isDirty }) => (
                                    <Select
                                        placeholder={'Select Status'}
                                        classNamePrefix="react-select-sol-style"
                                        maxMenuHeight={200}
                                        value={value}
                                        isClearable={false}
                                        options={contractStatuses}
                                        isSearchable={true}
                                        styles={customStylesForSingleSelectField}
                                        onChange={(selected, {action}) => {
                                            onChange(selected)
                                        }}
                                    />
                                )}
                            />
                        </Form.Group>
                    </div>
                </div>
                <div className={'row g-3'}>
                    <div className={'col-md-3'}>
                        <Form.Group>
                            <div>
                                <Form.Label>Borrower</Form.Label>
                            </div>
                            <Select
                                placeholder={'Select Borrower'}
                                name={'borrower'} // This name is used to show 'n items selected' text
                                classNamePrefix="react-select-sol-style"
                                isDisabled={props.garageListCollectionInProgress}
                                isLoading={props.garageListCollectionInProgress}
                                maxMenuHeight={200}
                                value={borrower}
                                isClearable={true}
                                options={borrowers}
                                isSearchable={true}
                                isMulti={true}
                                hideSelectedOptions={false}
                                styles={customStylesForMultiSelectField}
                                components={{ ValueContainer, Option }}
                                onChange={(selected, {action}) => {
                                    if (action === "clear") {
                                        setBorrower('')
                                    }
                                    if (selected) {
                                        setBorrower(selected)
                                        setBorrowers(groupSelectedOptions(selected, borrowers))
                                    }
                                }}
                            />
                        </Form.Group>
                    </div>
                    <div className={'col-md-3'}>
                        <Form.Group>
                            <div>
                                <Form.Label>Garage</Form.Label>
                            </div>
                            <Select
                                placeholder={'Select Garage'}
                                name={'garage'} // This name is used to show 'n items selected' text
                                classNamePrefix="react-select-sol-style"
                                isDisabled={props.garageListCollectionInProgress}
                                isLoading={props.garageListCollectionInProgress}
                                maxMenuHeight={200}
                                value={garage}
                                isClearable={true}
                                options={garages}
                                isSearchable={true}
                                isMulti={true}
                                hideSelectedOptions={false}
                                styles={customStylesForMultiSelectField}
                                components={{ ValueContainer, Option }}
                                onChange={(selected, {action}) => {
                                    if (action === "clear") {
                                        setGarage('')
                                    }
                                    if (selected) {
                                        setGarage(selected)
                                        setGarages(groupSelectedOptions(selected, garages))
                                    }
                                }}
                            />
                        </Form.Group>
                    </div>
                    {userIsFromSolshare? <>
                        <div className={'col-md-3'}>
                            <Form.Group>
                                <div>
                                    <Form.Label>Financier</Form.Label>
                                </div>
                                <Select
                                    placeholder={'Select financier'}
                                    name={'financier'} // This name is used to show 'n items selected' text
                                    classNamePrefix="react-select-sol-style"
                                    isDisabled={props.collectingFinanciers}
                                    isLoading={props.collectingFinanciers}
                                    maxMenuHeight={200}
                                    value={financier}
                                    isClearable={true}
                                    options={financiers}
                                    isSearchable={true}
                                    isMulti={true}
                                    hideSelectedOptions={false}
                                    styles={customStylesForMultiSelectField}
                                    components={{ ValueContainer, Option }}
                                    onChange={(selected, {action}) => {
                                        if (action === "clear") {
                                            setFinancier('')
                                        }
                                        if (selected) {
                                            setFinancier(selected)
                                            setFinanciers(groupSelectedOptions(selected, financiers))
                                        }
                                    }}
                                />
                            </Form.Group>
                        </div>
                    </>:''}
                </div>
                <div className={'row'}>
                    <div className={"col-md-12"}>
                        <Button variant="warning" size={'sm'} type="submit"
                                disabled={props.loanCollectionInProgress}>
                            Apply filter
                        </Button>
                        <Button variant="outline-dark" size={'sm'} disabled={props.loanCollectionInProgress}
                                onClick={()=> {resetFilter()}} className={'ml-3'}>
                            Reset filter
                        </Button>
                    </div>
                </div>
            </Form>
        </>
    }

    useEffect(() => {
        if (urlIsEdited && Object.keys(errors).length === 0) {
            if (!userIsPaginating) {
                setTotalContracts(0)
                setNumberOfActiveBorrowers(0)
                setNumberOfActiveGarages(0)
                setNumberOfActiveFinanciers(0)
                setTotalContractAmount(0)
                setTotalPaidAmount(0)
                setShowToolTipIcon(false)
            }
            // Page will always be there
            let filter = {}
            if (smartBatteryFromUrl) {
                filter['device_serial_number'] = smartBatteryFromUrl
            }
            if (dongleFromUrl) {
                filter['dongle_serial_number'] = dongleFromUrl
            }
            if (statusFromUrl) {
                filter['status'] = statusFromUrl
            }
            if (filterParamForApiCall.borrower) {
                filter['garage_guid'] = filterParamForApiCall.borrower
            }
            // Note that following is replacing the value of the above 'garage_guid' key but it won't have any effect as both of the values are same
            // Because we are showing borrower list from the garage list API
            if (filterParamForApiCall.garage) {
                filter['garage_guid'] = filterParamForApiCall.garage
            }
            if (filterParamForApiCall.financier) {
                filter['financier_guids'] = filterParamForApiCall.financier
            }
            if (contractIdFromUrl) {
                filter['contract_id'] = contractIdFromUrl
            }
            setFilterData({...filter})
            filter['page'] = page
            COLLECT_LOAN_LIST(filter)
            GET_GARAGE_LIST()
            setUserIsPaginating(false)
            setUrlIsEdited(false)
        }
    }, [urlIsEdited]);

    const filterTheList = (data) => {
        setPage(1)
        let searchText = 'page=1'
        if (data.contract_id) {
            searchText += '&contract_id=' + data.contract_id
        }
        if (data.smart_battery) {
            searchText += '&smart_battery=' + data.smart_battery
        }
        if (data.dongle) {
            searchText += '&dongle=' + data.dongle
        }
        // Status will always be there
        searchText += '&status=' + data.status.value
        if (borrower) {
            searchText += '&borrower=' + formulateApiRequestDataFromSelectedItems(borrower, 'value')
        }
        if (garage) {
            searchText += '&garage=' + formulateApiRequestDataFromSelectedItems(garage, 'value')
        }
        if (financier) {
            searchText += '&financier=' + formulateApiRequestDataFromSelectedItems(financier)
        }
        if (searchText !== 'page=1' || (searchText === 'page=1' && Object.keys(filterData).length > 0)) {
            // Allowing users to filter in following cases:
            // Case 1 - When filter criteria have been provided
            // Case 2 - When filter criteria have not been provided but data has already been filtered earlier
            // - Noor Reza, June 5, 2024 4:36 PM
            props.history.push({
                search: searchText
            })
        }
    }

    const resetFilter = () => {
        resetFilterValues()
        clearErrors()
        if (Object.keys(filterData).length > 0) {
            // Resetting
            setPage(1)
            props.history.push({
                search: 'page=1&status=active'
            })
        }
    }

    const resetFilterValues = () => {
        setValue('contract_id', '')
        setContractIdFromUrl('')

        setValue('smart_battery', '')
        setSmartBatteryFromUrl('')

        setValue('dongle', '')
        setDongleFromUrl('')

        setValue('status', contractStatuses[1])
        setStatusFromUrl(contractStatuses[1].value)

        setBorrower('')
        setBorrowerFromUrl('')

        setGarage('')
        setGarageFromUrl('')

        setFinancier('')
        setFinancierFromUrl('')
    }

    const filterToggle = () => {
        setShowFilter(!showFilter)
    }

    useEffect(() => {
        if (props.garagesForFilterField) {
            const garages = props.garagesForFilterField;
            if (garages.length > 0) {
                let selectedGarages = []
                let garageList = []

                let selectedBorrowers = []
                let borrowerList = []

                for (let i=0; i<garages.length; i++) {
                    if (garageFromUrl) {
                        let garageGuids = garageFromUrl.split(',')
                        if (garageGuids.includes(garages[i].pk)) {
                            selectedGarages.push({
                                ...garages[i],
                                label: garages[i].name,
                                value: garages[i].pk
                            })
                        }
                    }
                    garageList.push({
                        ...garages[i],
                        label: garages[i].name,
                        value: garages[i].pk
                    })

                    if (borrowerFromUrl) {
                        let borrowerUsernames = borrowerFromUrl.split(',')
                        if (borrowerUsernames.includes(garages[i].pk)) {
                            selectedBorrowers.push({
                                ...garages[i],
                                label: garages[i].garage_owner_name,
                                value: garages[i].pk
                            })
                        }
                    }
                    borrowerList.push({
                        ...garages[i],
                        label: garages[i].garage_owner_name,
                        value: garages[i].pk
                    })
                }

                if (selectedGarages.length > 0) {
                    setGarage(selectedGarages)
                    setGarages(groupSelectedOptions(selectedGarages, garageList))
                } else {
                    setGarages(garageList)
                }

                if (selectedBorrowers.length > 0) {
                    setBorrower(selectedBorrowers)
                    setBorrowers(groupSelectedOptions(selectedBorrowers, borrowerList))
                } else {
                    setBorrowers(borrowerList)
                }
            }
        }
    }, [props.garagesForFilterField])

    useEffect(() => {
        if (props.errorMessageRegardingGarageListCollection) {
            showNotifications('error', props.errorMessageRegardingGarageListCollection)
        }
    }, [props.errorMessageRegardingGarageListCollection])

    useEffect(() => {
        if (props.financiers) {
            const financiers = props.financiers;
            if (financiers.length > -1) {
                let selectedFinanciers =[]
                const financierList = financiers.map((financier) => {
                    if (financierFromUrl) {
                        let financierGuids = financierFromUrl.split(',')
                        if (financierGuids.includes(financier.pk)) {
                            selectedFinanciers.push({
                                ...financier,
                                label: financier.name,
                                value: financier.pk
                            })
                        }
                    }
                    return {
                        ...financier,
                        label: financier.name,
                        value: financier.pk
                    }
                })
                if (selectedFinanciers.length > 0) {
                    setFinancier(selectedFinanciers)
                    setFinanciers(groupSelectedOptions(selectedFinanciers, financierList))
                } else {
                    setFinanciers(financierList)
                }
            }
        }
    }, [props.financiers])

    const checkForUnits = (str) => {
        const unitsPattern = /(K|M|B|T|Q|Qi|Sx)$/;
        return unitsPattern.test(str);
    }

    useEffect(() => {
        if (props.loans && props.loans.results.length > -1) {
            setTotalContracts(props.loans.number_of_contracts)
            setNumberOfActiveBorrowers(props.loans.number_of_borrowers)
            setNumberOfActiveGarages(props.loans.number_of_garages)
            setNumberOfActiveFinanciers(props.loans.number_of_financier)
            setTotalPaidAmount(formatLargeCurrency(parseFloat(props.loans.total_paid_amount.toFixed(2))))
            let formattedTotalContractAmount = formatLargeCurrency(parseFloat(props.loans.total_loan_amount.toFixed(2)))
            setTotalContractAmount(formattedTotalContractAmount)
            if (checkForUnits(formattedTotalContractAmount)) setShowToolTipIcon(true)
            const loans = props.loans.results;
            setLoans(loans.map((loan) => {
                let garageName = loan.garage_name? loan.garage_name:''
                let borrowerName = loan.borrower_name
                if (garagesForValidation && garagesForValidation.length > -1) {
                    let filteredGarage = garagesForValidation.filter(garage => garage.pk === loan.garage_guid)
                    if (filteredGarage.length > 0) {
                        garageName = filteredGarage[0].name
                        borrowerName = filteredGarage[0].garage_owner_name
                    }
                } else if (garages && garages.length > 0) {
                    let filteredGarage = garages.filter(garage => garage.pk === loan.garage_guid)
                    if (filteredGarage.length > 0) {
                        garageName = filteredGarage[0].name
                        borrowerName = filteredGarage[0].garage_owner_name
                    }
                }

                let financierName = loan.financier_name
                let financierAddress = ''
                if (financiers && financiers.length > 0) {
                    let filteredFinancier = financiers.filter(financier => financier.pk === loan.financier_guid)
                    if (filteredFinancier.length > 0) {
                        financierName = filteredFinancier[0].name
                        financierAddress = filteredFinancier[0].address
                    }
                }

                let dueInstallmentCount = 0
                if (loan.loan_schedules && loan.loan_schedules.length > 0) {
                    let loanSchedules = loan.loan_schedules
                    for (let i=0; i<loanSchedules.length; i++) {
                        if (loanSchedules[i].status === 'due') {
                            dueInstallmentCount += 1
                        }
                    }
                }
                let dueActivityText = ''
                if (dueInstallmentCount) {
                    dueActivityText = dueInstallmentCount + ' Installments Remaining'
                }
                return {
                    ...loan,
                    garageName: garageName,
                    borrowerName: borrowerName,
                    financierName: financierName,
                    financierAddress: financierAddress,
                    dueActivityText: dueActivityText
                }
            }))
        }
    }, [props.loans, garages, garagesForValidation, financiers])
    // ----- End: Section related to filter process ---- //


    // ----- Begin: Section related to shareable link/handling query params ---- //
    const validationKeyArray = ['page', 'contract_id', 'smart_battery', 'dongle', 'status', 'borrower',
        'garage', 'financier']

    useEffect(() => {
        let temp = null;
        try {
            temp = parseParams(props.history?.location?.search);
            if (Object.keys(temp)?.length > 0) {
                /* Set the projected filter lists. Requires validation */
                setQueryParams(temp);
            }
        } catch (e) {
            console.debug(e);
        }
    },[props.history.location])

    useEffect(() => {
        let keys = null;
        const validParameters = {};
        /* Only these keys in the projected params are valid in the projected params */

        /* Getting the keys in the projected params */
        try {
            keys = Object.keys(queryParams);
        } catch (e) {
            console.log(e)
            return;
        }
        /* Checking for if keys value is an array and has at least one value */
        if (Array.isArray(keys) && keys.length > 0) {
            /* Looping through each of the value in the keys. (Essentially This loop will run for every key in the projected params object) */
            keys.forEach((item) => {
                /* We are checking if the key is valid by checking against validationKeyArray. if it matches we include it in the validParams object */
                const itemExists = validationKeyArray.includes(item)
                if (itemExists) {
                    Object.assign(validParameters, {[`${item}`]: queryParams[item]})
                }
            })
        }
        setInitialfilterParam(Object.keys(validParameters?.length > 0) ? {...validParameters} : null)
        setFilterParamForApiCall(Object.keys(validParameters?.length > 0) ? {...validParameters} : null)
    }, [queryParams])

    useEffect(() => {
        if (!isNullorUndefined(initialfilterParam)) {
            setUrlIsEdited(true)
            if (initialfilterParam.page) {
                let page = initialfilterParam.page
                if (/^(0|[1-9][0-9]*)$/i.test(page)) {
                    setPage(parseInt(page))
                } else {
                    toast.warn("Please provide only number as page count! Collecting data for first page..")
                }
            }
            if (initialfilterParam.contract_id) {
                let contractId = initialfilterParam.contract_id
                if (/^(0|[1-9][0-9]*)$/i.test(contractId)) {
                    setContractIdFromUrl(contractId)
                    setValue("contract_id", contractId)
                } else {
                    toast.warn("Invalid contract ID provided via url! Collecting data without filtering based on contract ID..")
                }
            } else {
                setContractIdFromUrl('')
            }
            if (initialfilterParam.smart_battery) {
                let smartBattery = initialfilterParam.smart_battery
                if (/^(0|[1-9][0-9]*)$/i.test(smartBattery)) {
                    setSmartBatteryFromUrl(smartBattery)
                    setValue("smart_battery", smartBattery)
                } else {
                    toast.warn("Invalid smart battery serial provided via url! Collecting data without filtering based on smart battery..")
                }
            } else {
                setSmartBatteryFromUrl('')
            }
            if (initialfilterParam.dongle) {
                let dongle = initialfilterParam.dongle
                if (/^(0|[1-9][0-9]*)$/i.test(dongle)) {
                    setDongleFromUrl(dongle)
                    setValue("dongle", dongle)
                } else {
                    toast.warn("Invalid dongle serial provided via url! Collecting data without filtering based on dongle..")
                }
            } else {
                setDongleFromUrl('')
            }
            // Status will always be there
            let statusExists = contractStatuses.filter(item => item.value === initialfilterParam.status)
            if (statusExists[0]) {
                setValue('status', statusExists[0])
                setStatusFromUrl(initialfilterParam.status)
            } else {
                setValue('status', contractStatuses[1])
                setStatusFromUrl(contractStatuses[1].value)
            }
            if (initialfilterParam.borrower) {
                let borrowerUserNameFromUrl = initialfilterParam.borrower
                setBorrowerFromUrl(borrowerUserNameFromUrl)
            } else {
                setBorrowerFromUrl('')
            }
            if (initialfilterParam.garage) {
                let garageInfoFromUrl = initialfilterParam.garage
                setGarageFromUrl(garageInfoFromUrl)
            } else {
                setGarageFromUrl('')
            }
            if (initialfilterParam.financier) {
                let financierGuidFromUrl = initialfilterParam.financier
                setFinancierFromUrl(financierGuidFromUrl)
            } else {
                setFinancierFromUrl('')
            }
            setInitialfilterParam(null)
        }
    }, [initialfilterParam])
    // ----- End: Section related to shareable link/handling query params ---- //

    // ----- Begin: Section related to export data ---- //
    const exportData = () => {
        COLLECT_LOAN_LIST_FOR_EXPORT({...filterData})
        GET_GARAGE_LIST()
    }

    useEffect(() => {
        if (props.loansForExport && props.loansForExport.length > -1) {
            const loans = props.loansForExport
            let presentTime = moment()
            let loanDataLength = loans.length
            let totalLoanText = "Total Contracts: " + loanDataLength
            let exportText = "Exported at: " + presentTime.format("Do MMMM,YYYY hh:mm A")
            let excelRows = [
                [pageTitle, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                [totalLoanText, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                [exportText, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
                ["Contract ID", "Smart Battery", "Borrower", "Financier", "Battery Ownership", "Region/ Area / Branch",
                    "Starting Date", "Contract Model", "Ownership Model", "Payment Model", "Investment Amount (BDT)",
                    "Collected Amount (BDT)", "Remaining Amount (BDT)", "Payment / Installment Rate (BDT)",
                    "Remaining Contract Duration", "Remaining installments till break even", "Payment Frequency", "Status"]
            ]
            if (smartBatteryFromUrl) {
                let text = "Smart Battery: " + smartBatteryFromUrl
                excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""])
            }
            if (dongleFromUrl) {
                let text = "SOLdongle: " + dongleFromUrl
                excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""])
            }
            if (statusFromUrl) {
                let text = "Contract Status: " + contractStatuses.filter(item => item.value === statusFromUrl)[0].label
                excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""])
            }
            if (filterData.garage_guid && borrower) {
                let text = formulateTextFromSelectedItems('Borrower', borrower)
                excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""])
            }
            if (filterData.garage_guid && garage) {
                let text = formulateTextFromSelectedItems('Garage', garage)
                excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""])
            }
            if (filterData.financier_guids && financier) {
                let text = formulateTextFromSelectedItems('Financier', financier)
                excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""])
            }
            if (contractIdFromUrl) {
                let text = "Contract ID: " + contractIdFromUrl
                excelRows.splice(1, 0, [text, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""])
            }
            for (let i=0; i<loans.length; i++) {
                let repaymentText = 'N/A'
                if ((loans[i].lease_type && loans[i].lease_type === 'rent') || loans[i].contract_type === 'rent') {
                    repaymentText = 'Tk ' + new Intl.NumberFormat('en-IN').format(loans[i].per_rent_payable.toFixed(2)) + ' / Rent'
                } else {
                    if (loans[i].lease_payment_interval === 'weekly') {
                        repaymentText = 'Tk ' +
                            new Intl.NumberFormat('en-IN').format(loans[i].per_installment_payable.toFixed(2)) + ' / Week'
                    } else {
                        // Monthly lease
                        repaymentText = 'Tk ' +
                            new Intl.NumberFormat('en-IN').format(loans[i].per_installment_payable.toFixed(2)) + ' / Month'
                    }
                }
                // let garageName = loans[i].garage_name? loans[i].garage_name:''
                let borrowerName = loans[i].borrower_name
                if (garagesForValidation && garagesForValidation.length > 0) {
                    let filteredGarage = garagesForValidation.filter(garage => garage.pk === loans[i].garage_guid)
                    if (filteredGarage.length > 0) {
                        // garageName = filteredGarage[0].name
                        borrowerName = filteredGarage[0].garage_owner_name
                    }
                } else if (garages && garages.length > 0) {
                    let filteredGarage = garages.filter(garage => garage.pk === loans[i].garage_guid)
                    if (filteredGarage.length > 0) {
                        // garageName = filteredGarage[0].name
                        borrowerName = filteredGarage[0].garage_owner_name
                    }
                }

                let financierName = loans[i].financier_name
                let financierAddress = ''
                if (financiers && financiers.length > 0) {
                    let filteredFinancier = financiers.filter(financier => financier.pk === loans[i].financier_guid)
                    if (filteredFinancier.length > 0) {
                        financierName = filteredFinancier[0].name
                        financierAddress = filteredFinancier[0].address
                    }
                }

                let dueInstallmentCount = 0
                if (loans[i].loan_schedules && loans[i].loan_schedules.length > 0) {
                    let loanSchedules = loans[i].loan_schedules
                    for (let j= 0; j < loanSchedules.length; j++) {
                        if (loanSchedules[j].status === 'due') {
                            dueInstallmentCount += 1
                        }
                    }
                }

                excelRows.push(
                    [
                        loans[i].contract_id,
                        'SB-' + loans[i].device_serial_number,
                        borrowerName,
                        financierName,
                        financierName,
                        financierAddress,
                        moment(loans[i].effective_from).format("Do MMMM, YYYY"),
                        (loans[i].lease_type && loans[i].lease_type === 'rent') || loans[i].contract_type === 'rent'? 'Rent':((loans[i].lease_type && loans[i].lease_type === 'loan') || loans[i].contract_type === 'loan')? 'Loan':'Lease',
                        loans[i].owned_by_user_after_contract_completion? 'Transferrable':'Non-Transferrable',
                        loans[i].prepaid_payment_enabled? 'Prepaid':'Postpaid',
                        Number(loans[i].loan_amount.toFixed(2)),
                        Number(loans[i].paid_amount.toFixed(2)),
                        Number(loans[i].outstanding_amount.toFixed(2)),
                        (loans[i].lease_type && loans[i].lease_type === 'rent') || loans[i].contract_type === 'rent'? Number(loans[i].per_rent_payable.toFixed(2)):Number(loans[i].per_installment_payable.toFixed(2)),
                        loans[i].effective_till? calculateTimeRemaining(loans[i].effective_till):'',
                        dueInstallmentCount? dueInstallmentCount:'',
                        (loans[i].lease_type && loans[i].lease_type === 'rent') || loans[i].contract_type === 'rent'? 'By Rent':(loans[i].lease_payment_interval === 'weekly'? 'Weekly':'Monthly'),
                        validStatusKeys.includes(loans[i].status)? contractStatuses.find(item => item.value === loans[i].status)['label']:'-'
                    ])

            }
            let noDataAvailableText =  "No contracts available"
            loans.length > 0? excelRows.push(["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""]):
                excelRows.push(["", "", "", "", "", "", "", "", noDataAvailableText, "", "", "", "", "", "", "", "", ""])
            let fileName = 'contracts_'
            exportInExcel(excelRows, fileName + presentTime.format("hh_mm_a_DD_MM_YYYY"))
        }
    }, [props.loansForExport])
    // ----- End: Section related to export data ---- //

    // ----- Begin: Section related to pagination ---- //
    const onChangePage = (event, newPage) => {
        setPage(newPage)
        setUserIsPaginating(true)
        let searchText = 'page=' + newPage
        if (smartBatteryFromUrl) {
            searchText += '&smart_battery=' + smartBatteryFromUrl
        }
        if (dongleFromUrl) {
            searchText += '&dongle=' + dongleFromUrl
        }
        if (statusFromUrl) {
            searchText += '&status=' + statusFromUrl
        }
        if (borrower) {
            searchText += '&borrower=' + formulateApiRequestDataFromSelectedItems(borrower, 'username')
        }
        if (garage) {
            searchText += '&garage=' + formulateApiRequestDataFromSelectedItems(garage)
        }
        if (financier) {
            searchText += '&financier=' + formulateApiRequestDataFromSelectedItems(financier)
        }
        if (contractIdFromUrl) {
            searchText += '&contract_id=' + contractIdFromUrl
        }
        props.history.push({
            search: searchText
        })
    }
    // ----- End: Section related to pagination ---- //

    const goToLoanDetails = (rowData) => {
        props.history.push({
            pathname: '/contract-management/details/' + rowData.contract_id
        })
    }

    // ----- Begin: Section related to summary information ---- //
    const contractManagementSummarySection = () => {
        return <>
            <div className={isTabletOrMobile? 'row':'row ml-1 mt-7'}>
                {/* We have designed this section as same as the Battery rent log summary section to make design aligned with Figma */}
                {/* Stack for desktop view and 'div' for mobile view, as div is a block level element and stack upon each other */}
                {/* - Noor Reza, 8th November, 2:30 PM */}
                {!isTabletOrMobile? <>
                    <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={20}>
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={1}
                        >
                            <Avatar alt={'Contracts'}
                                    src={require('../../../../utils/asset/ic-battery-rent-settlement.svg')}
                                    variant={'square'}/>
                            <Stack direction="column" justifyContent="flex-end" alignItems="flex-start" spacing={0}>
                                <h5><strong>{totalContracts}</strong></h5>
                                <small>Total Contracts</small>
                            </Stack>
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={1}
                        >
                            <Avatar alt={'Borrowers'}
                                    src={require('../../../../utils/asset/ic-person.svg')}
                                    variant={'square'}/>
                            <Stack direction="column" justifyContent="flex-end" alignItems="flex-start" spacing={0}>
                                <h5><strong>{numberOfActiveBorrowers}</strong></h5>
                                <small>Active Borrowers</small>
                            </Stack>
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={1}
                        >
                            <Avatar alt={'Garages'}
                                    src={require('../../../../utils/asset/ic-ev-garage.svg')}
                                    variant={'square'}/>
                            <Stack direction="column" justifyContent="flex-end" alignItems="flex-start" spacing={0}>
                                <h5><strong>{numberOfActiveGarages}</strong></h5>
                                <small>Active Garages</small>
                            </Stack>
                        </Stack>
                        {props.isSolshareUser? <>
                            <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={1}
                            >
                                <Avatar alt={'Financiers'}
                                        src={require('../../../../utils/asset/ic-building.svg')}
                                        variant={'square'}/>
                                <Stack direction="column" justifyContent="flex-end" alignItems="flex-start" spacing={0}>
                                    <h5><strong>{numberOfActiveFinanciers}</strong></h5>
                                    <small>Active Financiers</small>
                                </Stack>
                            </Stack>
                        </>:''}
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={1}
                        >
                            <Avatar alt={'Overview'}
                                    src={require('../../../../utils/asset/ic-clipboard-data.svg')}
                                    variant={'square'}/>
                            <Stack direction="column" justifyContent="flex-end" alignItems="flex-start" spacing={0}>
                                <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
                                    <h5 style={{color: '#0B6E4F'}}><strong>{totalPaidAmount}</strong></h5>
                                    <strong>/</strong>
                                    <strong>{totalContractAmount}</strong>
                                    {showToolTipIcon? <span>{toolTipForUnit()}</span>:''}
                                </Stack>
                                <small>Financial Overview (Recovered / Invested)</small>
                            </Stack>
                        </Stack>
                    </Stack>
                </>:<>
                    <div className={'col-md-12 mt-3'}>
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={1}
                        >
                            <Avatar alt={'Contracts'}
                                    src={require('../../../../utils/asset/ic-battery-rent-settlement.svg')}
                                    variant={'square'}/>
                            <Stack direction="column" justifyContent="flex-end" alignItems="flex-start" spacing={0}>
                                <h5><strong>{totalContracts}</strong></h5>
                                <small>Total Contracts</small>
                            </Stack>
                        </Stack>
                    </div>
                    <div className={'col-md-12 mt-10'}>
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={1}
                        >
                            <Avatar alt={'Borrowers'}
                                    src={require('../../../../utils/asset/ic-person.svg')}
                                    variant={'square'}/>
                            <Stack direction="column" justifyContent="flex-end" alignItems="flex-start" spacing={0}>
                                <h5><strong>{numberOfActiveBorrowers}</strong></h5>
                                <small>Active Borrowers</small>
                            </Stack>
                        </Stack>
                    </div>
                    <div className={'col-md-12 mt-10'}>
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={1}
                        >
                            <Avatar alt={'Garages'}
                                    src={require('../../../../utils/asset/ic-ev-garage.svg')}
                                    variant={'square'}/>
                            <Stack direction="column" justifyContent="flex-end" alignItems="flex-start" spacing={0}>
                                <h5><strong>{numberOfActiveGarages}</strong></h5>
                                <small>Active Garages</small>
                            </Stack>
                        </Stack>
                    </div>
                    <div className={'col-md-12 mt-10'}>
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={1}
                        >
                            <Avatar alt={'Financiers'}
                                    src={require('../../../../utils/asset/ic-building.svg')}
                                    variant={'square'}/>
                            <Stack direction="column" justifyContent="flex-end" alignItems="flex-start" spacing={0}>
                                <h5><strong>{numberOfActiveFinanciers}</strong></h5>
                                <small>Active Financiers</small>
                            </Stack>
                        </Stack>
                    </div>
                    <div className={'col-md-12 mt-10'}>
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={1}
                        >
                            <Avatar alt={'Overview'}
                                    src={require('../../../../utils/asset/ic-clipboard-data.svg')}
                                    variant={'square'}/>
                            <Stack direction="column" justifyContent="flex-end" alignItems="flex-start" spacing={0}>
                                <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
                                    <h5 style={{color: '#0B6E4F'}}><strong>{totalPaidAmount}</strong></h5>
                                    <strong>/</strong>
                                    <strong>{totalContractAmount}</strong>
                                    {showToolTipIcon? <span>{toolTipForUnit()}</span>:''}
                                </Stack>
                                <small>Financial Overview (Recovered / Invested)</small>
                            </Stack>
                        </Stack>
                    </div>
                </>}
            </div>
        </>
    }
    // ---- End: Section related to summary information ---- //

    return (
        // At mobile view, we are hiding displayed toolTip if user clicks anywhere on the page
        // Note that, it doesn't include the top section where action button resides
        // - Noor Reza, 8th November, 4:01 PM
        <div onClick={() => {if (isTabletOrMobile && showRepaymentEfficiencyTooltip) {setShowRepaymentEfficiencyTooltip(false)}}}>
            <ContentWrapper showCardHeader={false} showBackButton={false} isLoading={props.isLoading}
                            serverError={false} permissionDenied={false} pageTitle={pageTitle}
                            statusCode={props.statusCode}>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <Toolbar>
                            <Toolbar.Title>
                                <h1><b>{pageTitle}</b></h1>
                            </Toolbar.Title>
                            <Toolbar.ToolbarContainer>
                                <Toolbar.ToolbarContainer.Items>
                                    <IconButton onClick={filterToggle} classes={classes}>
                                        <FilterListIcon color="disabled" fontSize="large"/>
                                    </IconButton>
                                    <IconButton classes={classes} onClick={exportData}
                                                disabled={props.loanCollectionInProgress || props.exportDataCollectionInProgress}>
                                        {props.exportDataCollectionInProgress? <Spinner animation={"grow"} variant={'warning'}
                                                                                        size={"lg"}/> :
                                            <img src={require('../../../../utils/asset/download-Button.svg')}
                                                 alt="Download"/>}
                                    </IconButton>
                                </Toolbar.ToolbarContainer.Items>
                            </Toolbar.ToolbarContainer>
                        </Toolbar>
                    </div>
                </div>
                <hr/>

                {showFilter?
                    <>
                        {filterForm()}
                        <hr/>
                    </>:''}

                {contractManagementSummarySection()}
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <DataTableContainer>
                            {!props.loanCollectionInProgress? <DataTable
                                language={language}
                                noDataAvailableMessageInBangla={'কোন ঋণ নেই'}
                                noDataAvailableMessageInEnglish={'No contracts available'}
                                columns={loanListColumns}
                                data={loans}
                                showToolbar={false}
                                asyncPagination={true}
                                count={props.loans?.count}
                                itemsPerPage={props.loans?.page_size}
                                onChangePage={onChangePage}
                                page={page}
                                actionColumnIndex={-1}
                                overrideCustomActions={true}
                                actions={[
                                    {
                                        position: "row",
                                        action: (rowData) => ({
                                            icon: () => {
                                                return <Button variant={'warning'}
                                                               size={'sm'}
                                                               style={{ whiteSpace: 'nowrap' }} className={'ml-1'}>
                                                    Details
                                                </Button>
                                            },
                                            onClick: (e, row) => {goToLoanDetails(row)},
                                        })
                                    }
                                ]}
                            /> : <LoadingSpinner loadingSubText={'Collecting contracts ..'} language={language}/>}
                        </DataTableContainer>
                    </div>
                </div>
            </ContentWrapper>

            <style jsx>{`
              .filter-animation {
                animation-name: breath-in;
                animation-duration: 40ms;
              }

              @keyframes breath-in {
                0% {
                  height: 0;
                  opacity: 0;
                }
                10% {
                  height: 10px;
                  opacity: 0.10;
                }
                20% {
                  height: 20px;
                  opacity: 0.20;
                }
                30% {
                  height: 30px;
                  opacity: 0.30;
                }
                40% {
                  height: 40px;
                  opacity: 0.40;
                }
                50% {
                  height: 50px;
                  opacity: 0.50;
                }
                60% {
                  height: 60px;
                  opacity: 0.60;
                }
                70% {
                  height: 70px;
                  opacity: 0.70;
                }
                80% {
                  height: 80px;
                  opacity: 0.80;
                }
                90% {
                  height: 90px;
                  opacity: 0.90;
                }
                100% {
                  height: auto;
                  opacity: 1.00;
                }
              }

              //@keyframes breath-out {
              //    0% { height: auto;  opacity: 1.00; }
              //    10% { height: 90px;  opacity: 0.90; }
              //    20% { height: 80px;  opacity: 0.80; }
              //    30% { height: 70px;  opacity: 0.70; }
              //    40% { height: 60px;  opacity: 0.60; }
              //    50% { height: 50px;  opacity: 0.50; }
              //    60% { height: 40px;  opacity: 0.40; }
              //    70% { height: 30px;  opacity: 0.30; }
              //    80% { height: 20px;  opacity: 0.20; }
              //    90% { height: 10px;  opacity: 0.10; }
              //    100% { height: 0; opacity: 0;  }  
              //}


            `}</style>
        </div>
    );
};

Contracts.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isSolshareUser: state.auth.is_solshare_user,
        isLoading: state.contractManagementReducer.isLoading,
        iconLoading: state.contractManagementReducer.iconLoading,
        listLoading: state.contractManagementReducer.listLoading,
        garagesForFilterField: state.contractManagementReducer.garagesForFilterField,
        garageListCollectionInProgress: state.contractManagementReducer.garageListCollectionInProgress,
        errorMessageRegardingGarageListCollection: state.contractManagementReducer.errorMessageRegardingGarageListCollection,
        financiers: state.contractManagementReducer.financiers,
        collectingFinanciers: state.contractManagementReducer.collectingFinanciers,
        loans: state.contractManagementReducer.loans,
        loanCollectionInProgress: state.contractManagementReducer.loanCollectionInProgress,
        loansForExport: state.contractManagementReducer.loansForExport,
        exportDataCollectionInProgress: state.contractManagementReducer.exportDataCollectionInProgress,
        garagesForValidation: state.commonReducer.garages
    }
}

export default connect(mapStateToProps, {...actions, ...commonActions})(withRoles(Contracts));
