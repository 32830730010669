import React, { useEffect } from 'react';
import { useState } from 'react';
import {connect} from 'react-redux';
import {DataTable} from '../../../../../components';
import { actions } from '../../../actions';
import { actions as commonActions } from '../../../../commonReduxActions/actions';
import moment from "moment/moment";
import {GenericModal} from "../../../../../components/genericModal/genericModal";
import {DataTableContainerForLoanDetails} from "../../../utils";
import EditIcon from "@mui/icons-material/Edit";
import RescheduleInstallment from "../operations/rescheduleInstallment"
import Stack from "@mui/material/Stack";
import {useMediaQuery} from "react-responsive";
import Skeleton from "@mui/material/Skeleton";


const RepaymentSchedule = ({ contractDetails, ...props }) => {
    const [language, setLanguage] = useState("EN");
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

    const [totalPayable, setTotalPayable] = useState('');
    const [totalCollected, setTotalCollected] = useState('');
    const [totalRemaining, setTotalRemaining] = useState('');
    const [repaymentSchedules, setRepaymentSchedules] = useState(undefined);
    const [initialPageForRepaymentSchedculeTable, setInitialPageForRepaymentSchedculeTable] = useState(0);


    const repaymentScheduleColumns = [
        {
            field: 'installment_no',
            title: "Installment #",
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0.5',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0.5',
                textAlign: 'left'
            },
            render: (rowData)=>{
                return   <>
                    <h6 className={rowData.status === 'Overdue'? 'text-danger':''}
                        style={rowData.status === 'Paid' || rowData.status === 'Cancelled'? {color: '#8C8C9B'}:rowData.status === 'Due'? {color: '#F18D00'}:{}}><strong>{rowData.installment_no}</strong></h6>
                </>
            }
        },
        {
            field: 'paymentDate',
            title: "Payment Date",
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            disableClick: true,
            render: (rowData)=>{
                return   <>
                    <h6 className={rowData.status === 'Overdue'? 'text-danger':''}
                        style={rowData.status === 'Paid' || rowData.status === 'Cancelled'? {color: '#8C8C9B'}:rowData.status === 'Due'? {color: '#F18D00'}:{}}><strong>{rowData.paymentDate}</strong></h6>
                </>
            }
        },
        {
            field: 'payable_amount',
            title: "Amount Payable",
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData)=>{
                return   <>
                    <h6 className={rowData.status === 'Overdue'? 'text-danger':''}
                        style={rowData.status === 'Paid' || rowData.status === 'Cancelled'? {color: '#8C8C9B'}:rowData.status === 'Due'? {color: '#F18D00'}:{}}><strong>{new Intl.NumberFormat('en-IN').format(rowData.payable_amount.toFixed(2))}</strong></h6>
                </>
            }
        },
        {
            field: 'status',
            title: "Status",
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData)=>{
                return   <>
                    {rowData.status === 'Overdue'? <>
                        <h6 className={'text-danger'}><strong>{rowData.status}</strong></h6>
                    </>:rowData.status === 'Paid'? <>
                        <h6 className={'text-success'}><strong>{rowData.status}</strong></h6>
                    </>:rowData.status === 'Due'? <>
                        <h6 style={{color: '#F18D00'}}><strong>{rowData.status}</strong></h6>
                    </>:rowData.status === 'Cancelled'? <>
                        <h6 style={{color: '#8C8C9B'}}><strong>{rowData.status}</strong></h6>
                    </>:<>
                        <h6><strong>{rowData.status}</strong></h6>
                    </>}
                </>
            }
        }
    ]

    const {
        // Will add action here, if needed
    } = props;

    useEffect(() => {
        setLanguage(props.language)

        setTotalCollected(new Intl.NumberFormat('en-IN').format(contractDetails.paid_amount.toFixed(2)))
        setTotalRemaining(new Intl.NumberFormat('en-IN').format((contractDetails.loan_amount - contractDetails.paid_amount).toFixed(2)))
        setTotalPayable(new Intl.NumberFormat('en-IN').format((contractDetails.loan_amount).toFixed(2)))

        // Repayment Schedules
        let contractSchedules = contractDetails.loan_schedules
        if (contractSchedules && contractSchedules.length > -1) {
            let nextPaymentSerial = 0
            setRepaymentSchedules(contractSchedules.map((installment, index) => {
                let statusText = ''
                // At BE, payment date refers to the date when invoice becomes overdue if not paid within certain time
                // At BE, for normal (not rescheduled) invoice, payment date is one day before due_date
                // But for rescheduled invoice, due_date is considered as payment date as it holds the rescheduled date
                // Hence, the following checking is needed - Noor Reza, 14th May, 2024 3:51 pm
                let paymentDate = installment.rescheduled? moment(installment.due_date, "YYYY-MM-DD").format("MMM DD, YYYY"):moment(installment.due_date, "YYYY-MM-DD").subtract(1, 'days').format("MMM DD, YYYY")
                if (installment.status === 'paid') {
                    statusText = 'Paid'
                } else if (installment.status === 'overdue') {
                    statusText = 'Overdue'
                } else if (installment.status === 'cancelled') {
                    statusText = 'Cancelled'
                } else {
                    // Due
                    if (installment.invoice_id) {
                        // Normally there will always be only one due with invoice ID
                        nextPaymentSerial = index
                        statusText = 'Due'
                    } else {
                        statusText = 'Upcoming'
                    }
                }
                return {
                    ...installment,
                    status: statusText,
                    paymentDate: paymentDate
                }
            }))
            setInitialPageForRepaymentSchedculeTable(Math.floor(nextPaymentSerial / 5))
        }
    }, [])

    return (
        <>
            <div className={'row mt-10'}>
                <div className={'col-md-1'}></div>
                <div className={'col-md-4'}>
                    <h6><strong>Repayment Schedule</strong></h6>
                </div>
                {totalCollected && totalRemaining && totalPayable? <>
                    <div className={'col-md-6'}>
                        <Stack
                            direction="row"
                            justifyContent={!isTabletOrMobile? "flex-end":"flex-start"}
                            alignItems="flex-end"
                            spacing={4}
                        >
                            <span style={{color: '#0B6E4F'}}>Total Collected: {isTabletOrMobile? <br/>:''}TK {totalCollected}</span>
                            <span style={{color: '#cb152b'}}>Remaining: {isTabletOrMobile? <br/>:''}TK {totalRemaining}</span>
                            <span>Total Payable: {isTabletOrMobile? <br/>:''}TK {totalPayable}</span>
                        </Stack>
                    </div>
                </>:''}
            </div>
            <div className={'row mt-5'}>
                <div className={'col-md-1'}></div>
                <div className={'col-md-10'}>
                    {repaymentSchedules? <>
                        <DataTableContainerForLoanDetails>
                            <DataTable
                                language={language}
                                columns={repaymentScheduleColumns}
                                data={repaymentSchedules}
                                showToolbar={false}
                                asyncPagination={false}
                                pagination={true}
                                pageSize={5}
                                backGroundColorForSelectedRow={"#FDEDEE"}
                                initialPage={initialPageForRepaymentSchedculeTable}
                            />
                        </DataTableContainerForLoanDetails>
                    </>:<>
                        <h6>
                            <Skeleton animation="wave" variant="rectangular" width={'100%'} height={278}
                                      className={"d-flex justify-content-center align-items-center"}
                                      sx={{color: '#F18D00', backgroundColor: '#FAF9F6'}}>
                                Preparing repayment schedule for representation ...
                            </Skeleton>
                        </h6>
                    </>}
                </div>
                <div className={'col-md-1'}></div>
            </div>
        </>
    )
}

RepaymentSchedule.propTypes = {}

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations
    }
}

export default connect(mapStateToProps, { ...actions, ...commonActions })(RepaymentSchedule);