import React, { useEffect } from 'react';
import { useState } from 'react';
import {connect} from 'react-redux';
import {SolInput} from '../../../../../components';
import { actions } from '../../../actions';
import { actions as commonActions } from '../../../../commonReduxActions/actions';
import {toast} from "react-toastify";
import {Button, Spinner, Form} from "react-bootstrap";
import Stack from "@mui/material/Stack";
import {Controller, useForm} from "react-hook-form";
import Select from "react-select";
import {requestCycle} from "../../../../borrowers/utils";
import {showNotifications} from "../../../../../utils/notification";
import {SolTextArea} from "../../../../../components/SolStyledComponents/components";
import {customStylesForSingleSelectField} from "../../../../../utils/reactSelectRelated/customStylesForSingleSelect";
import InfoIcon from "@mui/icons-material/Info";
import {styled} from "@mui/material/styles";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";
import {useMediaQuery} from "react-responsive";
import {contractStatuses} from "../../components";


const UpdateContractStatus = ({ contractDetails, closeTheProcess, updateStatus, ...props }) => {
    const {register, errors, control, clearErrors, handleSubmit, reset, setValue, setError} = useForm()
    const [language, setLanguage] = useState("EN");
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    // Showing "Release Smart Battery" field related
    const [showReleaseSBField, setShowReleaseSBField] = useState(false);

    // Final submission and validation related variables
    const [disableSubmitButton, setDisableSubmitButton] = useState(false);
    const infoRequiredText = "Above information is required!"
    const invalidFileType = 'Invalid file type! Accepted file types are *.doc, *.docx, *.pdf, *.xls, *.xlsx, *.jpeg, *.jpg, *.webp, *.png and *.csv'
    const invalidSize = 'File size shouldn"t exceed 10MB!'

    // Contract status field related
    const [statusOptions, setStatusOptions] = useState([]);
    const [activeStatusColorCode, setActiveStatusColorCode] = useState("");
    const [otherStatusColorCode, setOtherStatusColorCode] = useState("");
    const customStylesForContractStatusSelection = {
        option: (styles, data) => {
            return {
                ...styles,
                color: data.isSelected? 'white':data.isFocused? 'inherit':data.value === 'active'? activeStatusColorCode: otherStatusColorCode,
                backgroundColor: data.isSelected || data.isFocused? '#F18D00': 'white'
            }
        },
        singleValue: (styles, selectedData) => {
            return {
                ...styles,
                color: selectedData.data.value === 'active'? activeStatusColorCode: otherStatusColorCode
            }
        }
    }

    // Release Smart Battery field related
    const decisions = [
        {
            label: 'Yes',
            value: 'yes'
        },
        {
            label: 'No',
            value: 'no'
        }
    ]

    // Tooltip for "Document"
    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
    }));
    const [showTooltipForDocumentField, setShowTooltipForDocumentField] = useState(false);
    const toolTipTextForDocumentField = <>
        <strong>Accepted file types:</strong> *.doc, *.docx, *.pdf, *.xls, *.xlsx, *.jpeg, '*.jpg, *.webp, *.png, *.csv<br/>
        <strong>Max accepted size:</strong> 10MB
    </>
    const toolTipForDocumentField = () => {
        return <>
            <HtmlTooltip
                title={toolTipTextForDocumentField} placement="right" arrow componentsProps={{
                tooltip: {
                    sx: {
                        '& .MuiTooltip-arrow': {
                            color: 'common.white'
                        }
                    }
                }}} open={showTooltipForDocumentField}
                onOpen={() => setShowTooltipForDocumentField(true)}
                onClose={() => setShowTooltipForDocumentField(false)}>
                <InfoIcon size={'sm'} style={{color: '#F18D00'}} onClick={() => {
                    if (isTabletOrMobile) {
                        setShowTooltipForDocumentField(!showTooltipForDocumentField)
                    }
                }}/>
            </HtmlTooltip>
        </>
    }

    const {
        UPDATE_CONTRACT_STATUS,
        COLLECT_LOAN_DETAILS,
        CLEAR_STATES_RELATED_TO_CONTRACT_STATUS_UPDATE_COMPONENT,
        RETURN_TO_INITIAL_STATE
    } = props;

    useEffect(() => {
        setLanguage(props.language)

        // Users will be able to set other statuses instead of "Paused"
        setStatusOptions(contractStatuses.filter(item => !['all', 'paused'].includes(item.value)))

        let activeStatus = contractStatuses.find(item => item.value === 'active')
        setActiveStatusColorCode(activeStatus? activeStatus['style']['color']:'')

        let terminatedStatus = contractStatuses.find(item => item.value === 'terminated')
        setOtherStatusColorCode(terminatedStatus? terminatedStatus['style']['color']:'')

        // "Update Status" will only be available to the "Active contract" (this status based checking has been applied at the Settings component)
        setValue("contract_status", activeStatus? activeStatus:'')

        return ()=> {
            CLEAR_STATES_RELATED_TO_CONTRACT_STATUS_UPDATE_COMPONENT()
        }
    }, [])

    const updateContractStatus = (data) => {
        let payload = {
            ...data,
            contract_id: contractDetails.contract_id,
            contract_status: data.contract_status.value,
            release_battery: data.contract_status.value === 'closed'? data.release_battery.value:data.contract_status.value === 'active'? 'no':'yes',
            attachment: data["attachment"][0]
        }
        UPDATE_CONTRACT_STATUS(payload)
    }

    useEffect(() => {
        if (props.contractStatusUpdated && props.contractStatusUpdated === requestCycle.success) {
            toast.success("Contract status is updated successfully!")
            closeTheProcess()
            COLLECT_LOAN_DETAILS(contractDetails.contract_id)
            RETURN_TO_INITIAL_STATE()
        }
    }, [props.contractStatusUpdated])

    useEffect(() => {
        if (props.errorRelatedToContractStatusUpdate) {
            showNotifications('error', props.errorRelatedToContractStatusUpdate)
        }
    }, [props.errorRelatedToContractStatusUpdate])

    return (
        <>
            <Form onSubmit={handleSubmit(updateContractStatus)}>
                <div className={'row g-3'}>
                    <div className={'col-md-6'}>
                        <Form.Group>
                            <div>
                                <Form.Label><h6><strong>Contract Status</strong> <span className="required text-danger">*</span></h6></Form.Label>
                            </div>
                            <Controller
                                control={control}
                                name={"contract_status"}
                                defaultValue={''}
                                rules={{required: infoRequiredText}}
                                render={( { onChange, onBlur, value, name, ref },
                                          { invalid, isTouched, isDirty }) => (
                                    <Select
                                        placeholder={'Select a status'}
                                        classNamePrefix="react-select-sol-style"
                                        maxMenuHeight={200}
                                        value={value}
                                        isClearable={true}
                                        control={control}
                                        inputRef={ref}
                                        options={statusOptions}
                                        isSearchable={true}
                                        styles={customStylesForContractStatusSelection}
                                        onChange={(selected, {action}) => {
                                            onChange(selected)
                                            setValue("release_battery", "")
                                            if (selected && selected.value === "closed") {
                                                setShowReleaseSBField(true)
                                            } else {
                                                setShowReleaseSBField(false)
                                            }
                                        }}
                                    />
                                )}
                            />
                            {errors.contract_status && <div className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.contract_status.message}</div>}
                        </Form.Group>
                    </div>
                    {showReleaseSBField? <>
                        <div className={'col-md-6'}>
                            <Form.Group>
                                <div>
                                    <Form.Label><h6><strong>Release Smart Battery</strong> <span className="required text-danger">*</span></h6></Form.Label>
                                </div>
                                <Controller
                                    control={control}
                                    name={"release_battery"}
                                    defaultValue={''}
                                    rules={{required: infoRequiredText}}
                                    render={( { onChange, onBlur, value, name, ref },
                                              { invalid, isTouched, isDirty }) => (
                                        <Select
                                            placeholder={'Select an option'}
                                            classNamePrefix="react-select-sol-style"
                                            maxMenuHeight={200}
                                            value={value}
                                            isClearable={true}
                                            control={control}
                                            inputRef={ref}
                                            options={decisions}
                                            isSearchable={true}
                                            styles={customStylesForSingleSelectField}
                                            onChange={(selected, {action}) => {
                                                onChange(selected)
                                            }}
                                        />
                                    )}
                                />
                                {errors.release_battery && <div className="text-danger">
                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.release_battery.message}</div>}
                            </Form.Group>
                        </div>
                    </>:''}
                </div>
                <div className={'row g-3'}>
                    <div className={'col-md-12'}>
                        <Form.Group>
                            <div>
                                <Form.Label>Supporting Document (if any) {toolTipForDocumentField()}</Form.Label>
                            </div>
                            <SolInput
                                name={"attachment"}
                                type={"file"}
                                ref={register()}
                                onClick={(e) => {
                                    // Clearing the file field value when user tries to re-upload
                                    setValue("attachment", "")
                                    clearErrors('attachment') // Clearing error to handle the case: user re-initiates file upload process after a wrong file upload, but this time he/she haven't uploaded a file
                                    setDisableSubmitButton(false)
                                }}
                                onChange={(e) => {
                                    if (e.target.files) {
                                        let value = e.target.files[0]
                                        if (value) {
                                            if (!["application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                                "application/pdf", "application/vnd.ms-excel",
                                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                                "image/jpeg", "image/webp", "image/png", "text/csv"].includes(value.type)) {
                                                setError('attachment', {'message': invalidFileType})
                                                setDisableSubmitButton(true)
                                            } else if (value.size/(1024 * 1024) > 10) {
                                                setError('attachment', {'message': invalidSize})
                                                setDisableSubmitButton(true)
                                            } else {
                                                clearErrors('attachment')
                                                setDisableSubmitButton(false)
                                            }
                                        }
                                    }
                                }}
                            />
                            {errors.attachment && <div className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.attachment.message}</div>}
                        </Form.Group>
                    </div>
                </div>
                <div className={'row g-3'}>
                    <div className={'col-md-12'}>
                        <Form.Group>
                            <div>
                                <Form.Label><h6><strong>Reason</strong> <span className="required text-danger">*</span></h6></Form.Label>
                            </div>
                            <SolTextArea
                                name={"reason"}
                                rows={"5"}
                                placeholder={"Reason for status update"}
                                autoComplete={"off"}
                                disabled={props.updatingContractStatus}
                                ref={register({
                                    required: infoRequiredText
                                })}
                            />
                            {errors.reason && <div className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.reason.message}</div>}
                        </Form.Group>
                    </div>
                </div>
                <hr/>
                <div className={'row mt-3'}>
                    <div className={"col-md-12"}>
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="flex-start"
                            spacing={2}
                        >
                            <Button variant={"outline-dark"} type={"button"} size={'lg'}
                                    disabled={props.contractStatusUpdateInProgress}
                                    onClick={closeTheProcess}>
                                Cancel
                            </Button>
                            <Button variant="warning" size={'lg'} type="submit" disabled={props.contractStatusUpdateInProgress || disableSubmitButton}>
                                {props.contractStatusUpdateInProgress? <><Spinner animation={'border'} size={'sm'} variant={'light'}/></>:null} Update
                            </Button>
                        </Stack>
                    </div>
                </div>
            </Form>
        </>
    )
}

UpdateContractStatus.propTypes = {}

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        contractStatusUpdated: state.contractManagementReducer.contractStatusUpdated,
        contractStatusUpdateInProgress: state.contractManagementReducer.contractStatusUpdateInProgress,
        errorRelatedToContractStatusUpdate: state.contractManagementReducer.errorRelatedToContractStatusUpdate
    }
}

export default connect(mapStateToProps, { ...actions, ...commonActions })(UpdateContractStatus);