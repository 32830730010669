import React, { useEffect } from 'react';
import { useState } from 'react';
import {connect} from 'react-redux';
import { actions } from '../../../actions';
import { actions as commonActions } from '../../../../commonReduxActions/actions';
import {toast} from "react-toastify";
import {Button, Dropdown, DropdownButton, Spinner} from "react-bootstrap";
import {requestCycle} from "../../../../borrowers/utils";
import {showNotifications} from "../../../../../utils/notification";
import SettingsIcon from "@mui/icons-material/Settings";
import EditIcon from "@mui/icons-material/Edit";
import {GenericModal} from "../../../../../components";


const ReleaseSmartBattery = ({ contractDetails, ...props }) => {
    const [language, setLanguage] = useState("EN");
    // Showing "Release Smart Battery confirmation modal" related
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const {
        RELEASE_SMART_BATTERY,
        COLLECT_LOAN_DETAILS,
        CLEAR_STATES_RELATED_TO_RELEASE_SMART_BATTERY_COMPONENT,
        RETURN_TO_INITIAL_STATE
    } = props;

    useEffect(() => {
        setLanguage(props.language)

        return ()=> {
            CLEAR_STATES_RELATED_TO_RELEASE_SMART_BATTERY_COMPONENT()
        }
    }, [])

    const openConfirmationModal = () => {
        // This function will be used to open the confirmation modal
        // It will be called when the user clicks on the "Release Smart Battery" menu
        setShowConfirmationModal(true)
    }

    const hideConfirmationModal = () => {
        // This function will be used to open the confirmation modal
        // It will be called when the user clicks on the "Release Smart Battery" menu
        setShowConfirmationModal(false)
    }

    const renderConfirmationModal = () => {
        return <>
            <GenericModal
                size={'md'}
                showModalHeader={true}
                footer={false}
                hideModal={hideConfirmationModal}
                modal={showConfirmationModal}
                title={<h1>Releasing Smart Battery <strong>{contractDetails.contract_id}</strong></h1>}>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <h4>Are you sure?</h4>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <Button variant="warning" size={'md'} type="button" className={'ml-3 float-right'}
                                onClick={releaseSmartBattery} disabled={props.batteryReleaseProcessInProgress}>
                            {props.batteryReleaseProcessInProgress? <><Spinner animation={'border'} size={'sm'} variant={'light'}/></>:null} Yes
                        </Button>
                        <Button variant="outline-dark" size={'md'} onClick={hideConfirmationModal}
                                className={'float-right'} disabled={props.batteryReleaseProcessInProgress}>
                            No
                        </Button>
                    </div>
                </div>
            </GenericModal>
        </>
    }

    const releaseSmartBattery = () => {
        RELEASE_SMART_BATTERY(contractDetails.contract_id)
    }

    useEffect(() => {
        if (props.batteryIsReleased && props.batteryIsReleased === requestCycle.success) {
            toast.success("Battery is released successfully!")
            setShowConfirmationModal(false)
            COLLECT_LOAN_DETAILS(contractDetails.contract_id)
            RETURN_TO_INITIAL_STATE()
        }
    }, [props.batteryIsReleased])

    useEffect(() => {
        if (props.errorRelatedToBatteryRelease) {
            showNotifications('error', props.errorRelatedToBatteryRelease)
        }
    }, [props.errorRelatedToBatteryRelease])

    return (
        <>
            <DropdownButton
                title={<span>Closed <SettingsIcon fontSize={"small"}/></span>}
                variant={"success"}
                id="sm-nested-dropdown" disabled={false}>
                <Dropdown.Item eventKey="1" size={'sm'}
                               onClick={openConfirmationModal}>
                    <div className={'container d-flex justify-content-between pl-0 pr-0'}>
                        <span>Release Smart Battery</span>
                        <EditIcon fontSize={"small"}/>
                    </div>
                </Dropdown.Item>
            </DropdownButton>
            {renderConfirmationModal()}
        </>
    )
}

ReleaseSmartBattery.propTypes = {}

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        batteryIsReleased: state.contractManagementReducer.batteryIsReleased,
        batteryReleaseProcessInProgress: state.contractManagementReducer.batteryReleaseProcessInProgress,
        errorRelatedToBatteryRelease: state.contractManagementReducer.errorRelatedToBatteryRelease,
        loanDetailsCollectionInProgress: state.contractManagementReducer.loanDetailsCollectionInProgress
    }
}

export default connect(mapStateToProps, { ...actions, ...commonActions })(ReleaseSmartBattery);