/**
 * Formats a large number either with commas (for numbers less than or equal to the threshold)
 * or in a compact format with appropriate units (K, M, B, T, Q, Qi, Sx) for larger numbers.
 *
 * If the number is less than or equal to the threshold (999,99,99,999), it is formatted
 * using the Indian numbering system with commas (e.g., "12,34,56,789").
 * If the number exceeds the threshold, it is scaled down and appended with a suitable unit
 * (e.g., "100.0B" for billions, "1.00Qi" for quintillions). For very large numbers,
 * the number is truncated to three significant digits, and the appropriate unit is added.
 *
 * Supports both regular decimal numbers and numbers in scientific notation (e.g., 7.2e+83).
 * For scientific notation, the first three digits are extracted and formatted with the unit.
 *
 * @param {number} number - The number to format. It can be an integer, decimal, or in scientific notation.
 * @returns {string} The formatted number as a string.
 *
 * @example
 * formatLargeCurrency(99999999999);
 * // Returns: "100.0B"
 *
 * @example
 * formatLargeCurrency(123456789);
 * // Returns: "12,34,56,789"
 *
 * @example
 * formatLargeCurrency(7.2e+83);
 * // Returns: "720Qi"
 */
export const formatLargeCurrency = (number) => {
    const units = ["", "K", "M", "B", "T", "Q", "Qi", "Sx"]; // Units for large numbers
    const threshold = 9999999999; // Number limit (999,99,99,999)

    // Convert input to a string to handle scientific notation if needed
    let numStr = number.toString();

    // Handle scientific notation by expanding it to a full number string
    if (numStr.includes('e')) {
        const [mantissa, exponent] = numStr.split('e').map(Number);
        const decimalPlaces = exponent > 0 ? exponent : 0;
        numStr = mantissa.toFixed(decimalPlaces).replace('.', '').padEnd(decimalPlaces + 1, '0');
    }

    // Convert back to number if possible for smaller values
    let num = Number(numStr);

    // If the number is less than or equal to the threshold, format with commas
    if (num <= threshold) {
        return new Intl.NumberFormat('en-IN').format(num);
    }

    // Apply the scaling logic only if the number is greater than the threshold
    let unitIndex = 0;
    while (num >= 1000 && unitIndex < units.length - 1) {
        num /= 1000;
        unitIndex++;
    }

    let precisedNumber = num.toPrecision(3)
    let eIndex = precisedNumber.indexOf('e')
    let result = eIndex !== -1 ? precisedNumber.slice(0, eIndex) : precisedNumber

    // Use toPrecision to handle floating-point precision issues and format the number
    return `${result}${units[unitIndex]}`;
}