import {actionTypes} from "./actionTypes";
import {exportGridListToCsv, requestCycle} from "./utils";
import {toast} from "react-toastify";

/*Initial State for grid*/
const initialState= {
    batteryList: undefined,
    batteryListForExport: undefined,
    isLoading: false,
    successMessage: undefined,
    errorMessage: undefined,
    successMessageForUpdate: undefined,
    errorMessageForUpdate: undefined,
    deviceCreated: undefined,
    batteryUpdated: undefined,
    performanceData: undefined,
    recordSaved: undefined
};

/*Reducer for Device's redux store*/
export const batteryReducer = (state=initialState, action)=> {
    /*Check the documentation for each reducer's purpose in the actionTypes file*/
    switch (action.type) {
        // Battery list
        case actionTypes.GET_BATTERY_LIST_REQUEST:
            return {
                ...state,
                listLoading: true,
                detailsInfoLoading: true, // Added for view details modal
                batteryList: undefined
            };
        case actionTypes.GET_BATTERY_LIST_SUCCESS:
            return {
                ...state,
                listLoading: false,
                detailsInfoLoading: false,
                batteryList: action.payload
            };
        case actionTypes.GET_BATTERY_LIST_FAILURE:
            toast.error("Can't collect some necessary info! Please contact with admin.");
            return {
                ...state,
                listLoading: false,
                detailsInfoLoading: false
            }

        // Battery list export
        case actionTypes.GET_BATTERY_LIST_FOR_EXPORT_REQUEST:
            return {
                ...state,
                collectingBatteryListForExport: true,
                batteryListForExport: undefined
            };
        case actionTypes.GET_BATTERY_LIST_FOR_EXPORT_SUCCESS:
            return {
                ...state,
                collectingBatteryListForExport: false,
                batteryListForExport: action.payload
            };
        case actionTypes.GET_BATTERY_LIST_FOR_EXPORT_FAILURE:
            toast.error("Can't collect battery list for export! Please contact with admin.");
            return {
                ...state,
                collectingBatteryListForExport: false
            }

        // Battery supplier list
        case actionTypes.GET_BATTERY_SUPPLIER_LIST_REQUEST:
            return {
                ...state,
                batterySuppliers: undefined,
                batterySuppliersDropDownLoading: true
            };
        case actionTypes.GET_BATTERY_SUPPLIER_LIST_SUCCESS:
            return {
                ...state,
                batterySuppliersDropDownLoading: false,
                batterySuppliers: action.payload
            };
        case actionTypes.GET_BATTERY_SUPPLIER_LIST_FAILURE:
            toast.error("Failed to collect the battery supplier list!");
            return {
                ...state,
                batterySuppliersDropDownLoading: false
            };

        // Vendor list
        case actionTypes.GET_BATTERY_VENDOR_LIST_REQUEST:
            return {
                ...state,
                batteryVendorListDropDownLoading: true
            };
        case actionTypes.GET_BATTERY_VENDOR_LIST_SUCCESS:
            return {
                ...state,
                batteryVendorListDropDownLoading: false,
                batteryVendors: action.payload
            };
        case actionTypes.GET_BATTERY_VENDOR_LIST_FAILURE:
            toast.error("Failed to collect the battery vendor list!");
            return {
                ...state,
                batteryVendorListDropDownLoading: false
            };

        // Battery model list
        case actionTypes.GET_BATTERY_MODEL_LIST_REQUEST:
            return {
                ...state,
                batteryModelListDropDownLoading: true
            };
        case actionTypes.GET_BATTERY_MODEL_LIST_SUCCESS:
            return {
                ...state,
                batteryModelListDropDownLoading: false,
                batteryModels: action.payload
            };
        case actionTypes.GET_BATTERY_MODEL_LIST_FAILURE:
            toast.error("Failed to collect the battery model list!");
            return {
                ...state,
                batteryModelListDropDownLoading: false
            };

        // BMS vendor list
        case actionTypes.GET_BMS_VENDOR_LIST_REQUEST:
            return {
                ...state,
                bmsVendorListDropDownLoading: true
            };
        case actionTypes.GET_BMS_VENDOR_LIST_SUCCESS:
            return {
                ...state,
                bmsVendorListDropDownLoading: false,
                bmsVendors: action.payload
            };
        case actionTypes.GET_BMS_VENDOR_LIST_FAILURE:
            toast.error("Failed to collect the bms vendor list!");
            return {
                ...state,
                bmsVendorListDropDownLoading: false
            };

        // BMS model list
        case actionTypes.GET_BMS_MODEL_LIST_REQUEST:
            return {
                ...state,
                bmsModelListDropDownLoading: true
            };
        case actionTypes.GET_BMS_MODEL_LIST_SUCCESS:
            return {
                ...state,
                bmsModelListDropDownLoading: false,
                bmsModels: action.payload
            };
        case actionTypes.GET_BMS_MODEL_LIST_FAILURE:
            toast.error("Failed to collect the bms model list!");
            return {
                ...state,
                bmsModelListDropDownLoading: false
            };

        // Add battery
        case actionTypes.ADD_BATTERY_REQUEST:
            return {
                ...state,
                batteryCreated: undefined,
                batteryInfoSubmitting: true
            };
        case actionTypes.ADD_BATTERY_SUCCESS:
            return {
                ...state,
                batteryCreated: requestCycle.success,
                successMessage: action.payload,
                batteryInfoSubmitting: false
            };
        case actionTypes.ADD_BATTERY_FAILURE:
            return {
                ...state,
                batteryCreated: requestCycle.failed,
                errorMessage: action.payload.error,
                batteryInfoSubmitting: false
            };

        // Update single battery
        case actionTypes.UPDATE_BATTERY_REQUEST:
            return {
                ...state,
                batteryUpdated: undefined,
                batteryUpdateInfoSubmitting: true
            };
        case actionTypes.UPDATE_BATTERY_SUCCESS:
            return {
                ...state,
                batteryUpdated: requestCycle.success,
                successMessageForUpdate: action.payload,
                batteryUpdateInfoSubmitting: false
            };
        case actionTypes.UPDATE_BATTERY_FAILURE:
            return {
                ...state,
                batteryUpdated: requestCycle.failed,
                errorMessageForUpdate: action.payload.error,
                batteryUpdateInfoSubmitting: false
            };

        // Generate batteries in a batch
        case actionTypes.GENERATE_BATTERY_BATCH_REQUEST:
            return {
                ...state,
                batteryGenerated: undefined,
                buttonLoading: true,
            };
        case actionTypes.GENERATE_BATTERY_BATCH_SUCCESS:
            toast.success('Batteries Generated')
            return {
                ...state,
                batteryGenerated: requestCycle.success,
                successMessageForCreate: action.payload.message,
                generatedBatteries: action.payload?.saved_batteries,
                buttonLoading: false,
            };
        case actionTypes.GENERATE_BATTERY_BATCH_FAILURE:
            toast.error('Batteries could not be generated')
            return {
                ...state,
                batteryGenerated: requestCycle.failed,
                errorMessageForCreate: action.payload.msg,
                buttonLoading: false,
            };

        // Update batteries in a batch
        case actionTypes.UPDATE_BATCH_BATTERIES_REQUEST:
            return {
                ...state,
                batteriesUpdated: undefined,
                buttonLoading: true,
            };
        case actionTypes.UPDATE_BATCH_BATTERIES_SUCCESS:
            toast.success('Batteries Updated')
            return {
                ...state,
                buttonLoading: false,
                batteriesUpdated: requestCycle.success,
                successMessageForUpdate: "Batteries Updated",
                updatedBatteries: action.payload.updated_batteries,
                rejectedBatteries: action.payload.rejected_batteries,
            };
        case actionTypes.UPDATE_BATCH_BATTERIES_FAILURE:
            toast.error('Batteries Could Not be updated')
            return {
                ...state,
                batteriesUpdated: requestCycle.failed,
                errorMessageForUpdate: action.payload.error,
                rejectedBatteries: action.payload.error.rejected_batteries,
                buttonLoading: false,
            };

        // Battery fleet performance
        case actionTypes.GET_BATTERY_PERFORMANCE_REQUEST:
            return {
                ...state,
                performanceData: undefined,
                collectingPerformanceData: true
            };
        case actionTypes.GET_BATTERY_PERFORMANCE_SUCCESS:
            return {
                ...state,
                collectingPerformanceData: false,
                performanceData: action.payload,
            }
        case actionTypes.GET_BATTERY_PERFORMANCE_FAILURE:
            toast.error("There is an error! Please contact with an admin.");
            return {
                ...state,
                collectingPerformanceData: false
            };

        // Lifetime AH OUT
        case actionTypes.GET_LIFETIME_AH_OUT_REQUEST:
            return {
                ...state,
                dischargeData: undefined,
                listLoading: true
            };
        case actionTypes.GET_LIFETIME_AH_OUT_SUCCESS:
            return {
                ...state,
                listLoading: false,
                dischargeData: action.payload
            };
        case actionTypes.GET_LIFETIME_AH_OUT_FAILURE:
            toast.error("Failed to collect the battery discharge data!");
            return {
                ...state,
                listLoading: false
            };

        // Battery logs
        case actionTypes.GET_BATTERY_LOGS_REQUEST:
            return {
                ...state,
                batterySuppliers: undefined,
                listLoading: true
            };
        case actionTypes.GET_BATTERY_LOGS_SUCCESS:
            return {
                ...state,
                listLoading: false,
                batteryLogs: action.payload
            };
        case actionTypes.GET_BATTERY_LOGS_FAILURE:
            toast.error("Failed to collect the battery log list!");
            return {
                ...state,
                listLoading: false
            };

        // Create Vednor
        case actionTypes.CREATE_VENDOR_REQUEST:
            return {
                ...state,
                buttonLoading: true,
                recordSaved: undefined,

            };
        case actionTypes.CREATE_VENDOR_SUCCESS:
            toast.success("Vendor created");
            return {
                ...state,
                buttonLoading: false,
                recordSaved: requestCycle.success

            };
        case actionTypes.CREATE_VENDOR_FAILURE:
            toast.error("Failed to create vendor");
            return {
                ...state,
                buttonLoading: false,
                recordSaved: requestCycle.failed

            };

        // Create Battery Model
        case actionTypes.CREATE_MODEL_REQUEST:
            return {
                ...state,
                buttonLoading: true,
                recordSaved: undefined
            };
        case actionTypes.CREATE_MODEL_SUCCESS:
            toast.success("Model Created!");
            return {
                ...state,
                buttonLoading: false,
                recordSaved: requestCycle.success
            };
        case actionTypes.CREATE_MODEL_FAILURE:
            toast.error("Failed to create model!");
            return {
                ...state,
                buttonLoading: false,
                recordSaved: requestCycle.failed
            };

        case actionTypes.GET_MODEL_DETAILS_REQUEST:
            return {
                ...state,
                
            };
        case actionTypes.GET_MODEL_DETAILS_SUCCESS:
            return {
                ...state,
                modelDetails: action.payload
                
            };
        case actionTypes.GET_MODEL_DETAILS_FAILURE:
            toast.error("Failed to get battery model");
            return {
                ...state,
                
            };

        case actionTypes.UPDATE_MODEL_REQUEST:
            return {
                ...state,
                buttonLoading: true,
                recordSaved: undefined
            };
        case actionTypes.UPDATE_MODEL_SUCCESS:
            toast.success("Model is updated successfully!");
            return {
                ...state,
                buttonLoading: false,
                recordSaved: requestCycle.success

            };
        case actionTypes.UPDATE_MODEL_FAILURE:
            toast.error("Failed to update model!");
            return {
                ...state,
                buttonLoading: false,
                recordSaved: requestCycle.failed

            };

        // Return to the initial values
        case actionTypes.RETURN_TO_INITIAL_STATE:
            return initialState

        default:
            return state;
    }
};
