import {Redirect, Switch} from "react-router-dom";
import React from "react";
import RentLogsDynamoDB from "./views/rentLog/rentLogsDynamoDB";
// import CheckOut from "./views/checkOut/checkOut";
import SecuredRoute from "../../router/SecuredRoute";
import {getServiceName} from "../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../utils/roleRelatedValues/actionNames";


export const RentLogRouter2 =(props)=> {

    return (
        <Switch>
            {/* Following url/path is added as it has been used in the automated mail throughout the company*/}
            <Redirect
                exact={"true"}
                from={"/dynamodb"}
                to={"/dynamodb/rent-logs"}
            />

            {/* Routes to Rent List from dynamoDB */}
            <SecuredRoute
                path={"/dynamodb/rent-logs"}
                component={RentLogsDynamoDB}
                service={getServiceName('garageService')}
                feature={getFeatureName('batteryRentFeature')}
                action={getActionName('listAction')}
            />

            <Redirect to="/error/error-v1" />
        </Switch>
    )
};