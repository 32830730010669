import {CALL_API} from "../../utils/actionTypes";
import {actionTypes} from "./actionTypes";
import {paramsHandler} from "../../utils/filterParamsHandler";
export const actions = {
    /* N.B: Check the documentation for each action's purpose in the actionTypes file */

    COLLECT_FINANCIERS: ()=>({
        [CALL_API]: {
            endpoint: '/organizations',
            method: 'GET',
            types: [
                actionTypes.COLLECT_FINANCIERS_REQUEST,
                actionTypes.COLLECT_FINANCIERS_SUCCESS,
                actionTypes.COLLECT_FINANCIERS_FAILURE,
            ]
        }
    }),

    COLLECT_LOAN_LIST: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler(`/contracts`, filter),
            method: 'GET',
            backend_slug: 'garage_management',
            types: [
                actionTypes.COLLECT_LOAN_LIST_REQUEST,
                actionTypes.COLLECT_LOAN_LIST_SUCCESS,
                actionTypes.COLLECT_LOAN_LIST_FAILURE,
            ]
        }
    }),

    COLLECT_LOAN_DETAILS: (loanId)=>({
        [CALL_API]: {
            endpoint: `/contract/` + loanId,
            method: 'GET',
            backend_slug: 'garage_management',
            types: [
                actionTypes.COLLECT_LOAN_DETAILS_REQUEST,
                actionTypes.COLLECT_LOAN_DETAILS_SUCCESS,
                actionTypes.COLLECT_LOAN_DETAILS_FAILURE,
            ]
        }
    }),

    COLLECT_OPERATIONAL_LOGS_OF_EDIT_CONTRACT: (contractId)=>({
        [CALL_API]: {
            endpoint: '/change_log?entity_for=device_contract_info_update&entity_sl=' + contractId,
            method: 'GET',
            backend_slug: 'device_management',
            types: [
                actionTypes.COLLECT_OPERATIONAL_LOGS_OF_EDIT_CONTRACT_REQUEST,
                actionTypes.COLLECT_OPERATIONAL_LOGS_OF_EDIT_CONTRACT_SUCCESS,
                actionTypes.COLLECT_OPERATIONAL_LOGS_OF_EDIT_CONTRACT_FAILURE,
            ]
        }
    }),

    COLLECT_OPERATIONAL_LOGS_OF_INSTALLMENT_UPDATE: (contractId)=>({
        [CALL_API]: {
            endpoint: '/change_log?entity_for=contract_installment_amount_update&entity_sl=' + contractId,
            method: 'GET',
            backend_slug: 'device_management',
            types: [
                actionTypes.COLLECT_OPERATIONAL_LOGS_OF_INSTALLMENT_UPDATE_REQUEST,
                actionTypes.COLLECT_OPERATIONAL_LOGS_OF_INSTALLMENT_UPDATE_SUCCESS,
                actionTypes.COLLECT_OPERATIONAL_LOGS_OF_INSTALLMENT_UPDATE_FAILURE,
            ]
        }
    }),

    COLLECT_OPERATIONAL_LOGS_OF_CONTRACT_STATUS_UPDATE: (contractId)=>({
        [CALL_API]: {
            endpoint: '/change_log?entity_for=contract_status_update&entity_sl=' + contractId,
            method: 'GET',
            backend_slug: 'device_management',
            types: [
                actionTypes.COLLECT_OPERATIONAL_LOGS_OF_CONTRACT_STATUS_UPDATE_REQUEST,
                actionTypes.COLLECT_OPERATIONAL_LOGS_OF_CONTRACT_STATUS_UPDATE_SUCCESS,
                actionTypes.COLLECT_OPERATIONAL_LOGS_OF_CONTRACT_STATUS_UPDATE_FAILURE,
            ]
        }
    }),

    COLLECT_LOAN_LIST_FOR_EXPORT: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler(`/contracts`, filter),
            method: 'GET',
            backend_slug: 'garage_management',
            types: [
                actionTypes.COLLECT_LOAN_LIST_FOR_EXPORT_REQUEST,
                actionTypes.COLLECT_LOAN_LIST_FOR_EXPORT_SUCCESS,
                actionTypes.COLLECT_LOAN_LIST_FOR_EXPORT_FAILURE,
            ]
        }
    }),

    COLLECT_SMART_BATTERY_LIST: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler(`/devices`, filter),
            method: 'GET',
            backend_slug: 'device_management',
            types: [
                actionTypes.COLLECT_SMART_BATTERY_LIST_REQUEST,
                actionTypes.COLLECT_SMART_BATTERY_LIST_SUCCESS,
                actionTypes.COLLECT_SMART_BATTERY_LIST_FAILURE,
            ]
        }
    }),

    COLLECT_SMART_DONGLE_LIST: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler(`/devices/dongle`, filter),
            method: 'GET',
            backend_slug: 'device_management',
            types: [
                actionTypes.COLLECT_SMART_DONGLE_LIST_REQUEST,
                actionTypes.COLLECT_SMART_DONGLE_LIST_SUCCESS,
                actionTypes.COLLECT_SMART_DONGLE_LIST_FAILURE
            ]
        }
    }),

    COLLECT_BORROWER_LIST: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler(`/users?user_type=garage_owner`, filter),
            method: 'GET',
            types: [
                actionTypes.COLLECT_BORROWER_LIST_REQUEST,
                actionTypes.COLLECT_BORROWER_LIST_SUCCESS,
                actionTypes.COLLECT_BORROWER_LIST_FAILURE
            ]
        }
    }),

    COLLECT_GARAGE_LIST: (filter={}) => ({
        [CALL_API]: {
            endpoint: paramsHandler('/garages', filter),
            method: 'GET',
            backend_slug: 'garage_management',
            types: [
                actionTypes.COLLECT_GARAGE_LIST_REQUEST,
                actionTypes.COLLECT_GARAGE_LIST_SUCCESS,
                actionTypes.COLLECT_GARAGE_LIST_FAILURE
            ]
        }
    }),

    COLLECT_DRIVER_LIST: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler('/drivers', filter),
            method: 'GET',
            backend_slug: 'garage_management',
            types: [
                actionTypes.COLLECT_DRIVER_LIST_REQUEST,
                actionTypes.COLLECT_DRIVER_LIST_SUCCESS,
                actionTypes.COLLECT_DRIVER_LIST_FAILURE
            ]
        }
    }),

    TERMINATE_LOAN_CONTRACT: (payload)=>({
        [CALL_API]: {
            endpoint: '/terminate_contract',
            method: 'POST',
            body: payload,
            formDataFlag: true,
            backend_slug: 'garage_management',
            types: [
                actionTypes.TERMINATE_LOAN_CONTRACT_REQUEST,
                actionTypes.TERMINATE_LOAN_CONTRACT_SUCCESS,
                actionTypes.TERMINATE_LOAN_CONTRACT_FAILURE
            ]
        }
    }),

    CREATE_LOAN_CONTRACT: (payload)=>({
        [CALL_API]: {
            endpoint: '/contracts',
            method: 'POST',
            body: payload,
            formDataFlag: true,
            backend_slug: 'garage_management',
            types: [
                actionTypes.CREATE_LOAN_CONTRACT_REQUEST,
                actionTypes.CREATE_LOAN_CONTRACT_SUCCESS,
                actionTypes.CREATE_LOAN_CONTRACT_FAILURE
            ]
        }
    }),

    RESCHEDULE_THE_INSTALLMENT: (payload)=>({
        [CALL_API]: {
            endpoint: '/invoices/reschedule',
            method: 'PUT',
            body: payload,
            backend_slug: 'transaction_management',
            types: [
                actionTypes.RESCHEDULE_THE_INSTALLMENT_REQUEST,
                actionTypes.RESCHEDULE_THE_INSTALLMENT_SUCCESS,
                actionTypes.RESCHEDULE_THE_INSTALLMENT_FAILURE
            ]
        }
    }),

    EDIT_CONTRACT: (payload, contractId)=>({
        [CALL_API]: {
            endpoint: '/contract/' + contractId,
            method: 'PUT',
            body: payload,
            backend_slug: 'garage_management',
            types: [
                actionTypes.EDIT_CONTRACT_REQUEST,
                actionTypes.EDIT_CONTRACT_SUCCESS,
                actionTypes.EDIT_CONTRACT_FAILURE
            ]
        }
    }),

    EXTEND_CONTRACT: (payload)=>({
        [CALL_API]: {
            endpoint: '/extend_contract_tenure',
            method: 'POST',
            body: payload,
            backend_slug: 'garage_management',
            types: [
                actionTypes.EXTEND_CONTRACT_REQUEST,
                actionTypes.EXTEND_CONTRACT_SUCCESS,
                actionTypes.EXTEND_CONTRACT_FAILURE
            ]
        }
    }),

    UPDATE_INSTALLMENT: (payload, contractId)=>({
        [CALL_API]: {
            endpoint: '/contract/installment-update/' + contractId,
            method: 'PUT',
            body: payload,
            formDataFlag: true,
            backend_slug: 'garage_management',
            types: [
                actionTypes.UPDATE_INSTALLMENT_REQUEST,
                actionTypes.UPDATE_INSTALLMENT_SUCCESS,
                actionTypes.UPDATE_INSTALLMENT_FAILURE
            ]
        }
    }),

    COLLECT_FILE: (guid)=>({
        [CALL_API]: {
            endpoint: '/change_log/download_file/' + guid,
            method: 'GET',
            backend_slug: 'device_management',
            types: [
                actionTypes.COLLECT_FILE_REQUEST,
                actionTypes.COLLECT_FILE_SUCCESS,
                actionTypes.COLLECT_FILE_FAILURE
            ]
        }
    }),

    PAUSE_OR_RESUME_CONTRACT: (payload, actionName)=>({
        [CALL_API]: {
            endpoint: actionName === 'Pause'? '/pause_contract':'/resume_contract',
            method: 'POST',
            body: payload,
            backend_slug: 'garage_management',
            types: [
                actionTypes.PAUSE_OR_RESUME_CONTRACT_REQUEST,
                actionTypes.PAUSE_OR_RESUME_CONTRACT_SUCCESS,
                actionTypes.PAUSE_OR_RESUME_CONTRACT_FAILURE
            ]
        }
    }),

    UPDATE_CONTRACT_STATUS: (payload)=>({
        [CALL_API]: {
            endpoint: '/update-status',
            method: 'POST',
            body: payload,
            formDataFlag: true,
            backend_slug: 'garage_management',
            types: [
                actionTypes.UPDATE_CONTRACT_STATUS_REQUEST,
                actionTypes.UPDATE_CONTRACT_STATUS_SUCCESS,
                actionTypes.UPDATE_CONTRACT_STATUS_FAILURE
            ]
        }
    }),

    RELEASE_SMART_BATTERY: (contractId)=>({
        [CALL_API]: {
            endpoint: '/release-battery?contract_id=' + contractId,
            method: 'POST',
            backend_slug: 'garage_management',
            types: [
                actionTypes.RELEASE_SMART_BATTERY_REQUEST,
                actionTypes.RELEASE_SMART_BATTERY_SUCCESS,
                actionTypes.RELEASE_SMART_BATTERY_FAILURE
            ]
        }
    }),

    CLEAR_STATES_RELATED_TO_EDIT_CONTRACT_COMPONENT: ()=>({type: actionTypes.CLEAR_STATES_RELATED_TO_EDIT_CONTRACT_COMPONENT}),
    CLEAR_STATES_RELATED_TO_TERMINATE_CONTRACT_COMPONENT: ()=>({type: actionTypes.CLEAR_STATES_RELATED_TO_TERMINATE_CONTRACT_COMPONENT}),
    CLEAR_STATES_RELATED_TO_RESCHEDULING_INSTALLMENT_COMPONENT: ()=>({type: actionTypes.CLEAR_STATES_RELATED_TO_RESCHEDULING_INSTALLMENT_COMPONENT}),
    CLEAR_STATES_RELATED_TO_EXTEND_CONTRACT_COMPONENT: ()=>({type: actionTypes.CLEAR_STATES_RELATED_TO_EXTEND_CONTRACT_COMPONENT}),
    CLEAR_STATES_RELATED_TO_UPDATE_INSTALLMENT_COMPONENT: ()=>({type: actionTypes.CLEAR_STATES_RELATED_TO_UPDATE_INSTALLMENT_COMPONENT}),
    CLEAR_STATES_RELATED_TO_PAUSE_OR_RESUME_CONTRACT_COMPONENT: ()=>({type: actionTypes.CLEAR_STATES_RELATED_TO_PAUSE_OR_RESUME_CONTRACT_COMPONENT}),
    CLEAR_STATES_RELATED_TO_CONTRACT_STATUS_UPDATE_COMPONENT: ()=>({type: actionTypes.CLEAR_STATES_RELATED_TO_CONTRACT_STATUS_UPDATE_COMPONENT}),
    CLEAR_STATES_RELATED_TO_RELEASE_SMART_BATTERY_COMPONENT: ()=>({type: actionTypes.CLEAR_STATES_RELATED_TO_RELEASE_SMART_BATTERY_COMPONENT}),
    RETURN_TO_INITIAL_STATE: ()=>({type: actionTypes.RETURN_TO_INITIAL_STATE})
};
