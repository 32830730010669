import {CALL_API} from "../../utils/actionTypes";
import {actionTypes} from "./actionTypes";
import {paramsHandler} from "../../utils/filterParamsHandler";
export const transactionActions = {
    /* N.B: Check the documentation for each action's purpose in the actionTypes file */

    COLLECT_INVOICES_LIST:(filter = {})=>({
        [CALL_API]: {
            endpoint: paramsHandler('/invoices', filter),
            method: 'GET',
            backend_slug: 'transaction_management',
            types: [
                actionTypes.COLLECT_INVOICES_LIST_REQUEST,
                actionTypes.COLLECT_INVOICES_LIST_SUCCESS,
                actionTypes.COLLECT_INVOICES_LIST_FAILURE,
            ]
        }
    }),

    COLLECT_INVOICES_LIST_FOR_EXPORT:(filter = {})=>({
        [CALL_API]: {
            endpoint: paramsHandler('/invoices', filter),
            method: 'GET',
            backend_slug: 'transaction_management',
            types: [
                actionTypes.COLLECT_INVOICES_LIST_FOR_EXPORT_REQUEST,
                actionTypes.COLLECT_INVOICES_LIST_FOR_EXPORT_SUCCESS,
                actionTypes.COLLECT_INVOICES_LIST_FOR_EXPORT_FAILURE,
            ]
        }
    }),

    COLLECT_FINANCIERS_LIST: ()=>({
        [CALL_API]: {
            endpoint: '/organizations',
            method: 'GET',
            types: [
                actionTypes.COLLECT_FINANCIERS_LIST_REQUEST,
                actionTypes.COLLECT_FINANCIERS_LIST_SUCCESS,
                actionTypes.COLLECT_FINANCIERS_LIST_FAILURE,
            ]
        }
    }),

    FETCH_PAYMENTS_FOR_EXPORT:(payload={})=>({
        [CALL_API]: {
            endpoint: paramsHandler('/bkash_payment',payload),
            method: 'GET',
            backend_slug: 'transaction_management',
            types: [
                actionTypes.FETCH_PAYMENTS_EXPORT_REQUEST,
                actionTypes.FETCH_PAYMENTS_EXPORT_SUCCESS,
                actionTypes.FETCH_PAYMENTS_EXPORT_FAILURE,
            ]
        }
    }),

    FETCH_BATTERY_SETTLEMENT_PAYMENTS: (payload = {}) => ({
        [CALL_API]: {
            endpoint: paramsHandler('/payment', payload),
            method: 'GET',
            backend_slug: 'transaction_management',
            types: [
                actionTypes.FETCH_BATTERY_SETTLEMENT_PAYMENTS_REQUEST,
                actionTypes.FETCH_BATTERY_SETTLEMENT_PAYMENTS_SUCCESS,
                actionTypes.FETCH_BATTERY_SETTLEMENT_PAYMENTS_FAILURE,
            ]
        }
    }),

    FETCH_BATTERY_SETTLEMENT_PAYMENTS_FOR_EXPORT: (payload = {}) => ({
        [CALL_API]: {
            endpoint: paramsHandler('/payment', payload),
            method: 'GET',
            backend_slug: 'transaction_management',
            types: [
                actionTypes.FETCH_BATTERY_SETTLEMENT_PAYMENTS_FOR_EXPORT_REQUEST,
                actionTypes.FETCH_BATTERY_SETTLEMENT_PAYMENTS_FOR_EXPORT_SUCCESS,
                actionTypes.FETCH_BATTERY_SETTLEMENT_PAYMENTS_FOR_EXPORT_FAILURE,
            ]
        }
    }),

    SETTLE_PAYMENTS: (payload, filter={}) => ({
        [CALL_API]: {
            endpoint: paramsHandler('/payment', filter),
            method: 'PUT',
            body: payload,
            backend_slug: 'transaction_management',
            types: [
                actionTypes.SETTLE_PAYMENTS_REQUEST,
                actionTypes.SETTLE_PAYMENTS_SUCCESS,
                actionTypes.SETTLE_PAYMENTS_FAILURE,
            ]
        }
    }),

    COLLECT_GARAGES_FOR_BULK_EXTENSION:()=>({
        [CALL_API]: {
            endpoint: '/garages',
            method: 'GET',
            backend_slug: 'garage_management',
            types: [
                actionTypes.COLLECT_GARAGES_FOR_BULK_EXTENSION_REQUEST,
                actionTypes.COLLECT_GARAGES_FOR_BULK_EXTENSION_SUCCESS,
                actionTypes.COLLECT_GARAGES_FOR_BULK_EXTENSION_FAILURE,
            ]
        }
    }),

    COLLECT_BORROWERS_FOR_BULK_EXTENSION:()=>({
        [CALL_API]: {
            endpoint: '/garages',
            method: 'GET',
            backend_slug: 'garage_management',
            types: [
                actionTypes.COLLECT_BORROWERS_FOR_BULK_EXTENSION_REQUEST,
                actionTypes.COLLECT_BORROWERS_FOR_BULK_EXTENSION_SUCCESS,
                actionTypes.COLLECT_BORROWERS_FOR_BULK_EXTENSION_FAILURE,
            ]
        }
    }),

    COLLECT_OVERDUE_INVOICES_FOR_BULK_EXTENSION:(filter = {})=>({
        [CALL_API]: {
            endpoint: paramsHandler('/invoices?invoice_status=overdue', filter),
            method: 'GET',
            backend_slug: 'transaction_management',
            types: [
                actionTypes.COLLECT_OVERDUE_INVOICES_FOR_BULK_EXTENSION_REQUEST,
                actionTypes.COLLECT_OVERDUE_INVOICES_FOR_BULK_EXTENSION_SUCCESS,
                actionTypes.COLLECT_OVERDUE_INVOICES_FOR_BULK_EXTENSION_FAILURE
            ]
        }
    }),

    COLLECT_UPCOMING_INVOICES_FOR_BULK_EXTENSION:(filter = {})=>({
        [CALL_API]: {
            endpoint: paramsHandler('/upcoming-loan-schedules', filter),
            method: 'GET',
            backend_slug: 'garage_management',
            types: [
                actionTypes.COLLECT_UPCOMING_INVOICES_FOR_BULK_EXTENSION_REQUEST,
                actionTypes.COLLECT_UPCOMING_INVOICES_FOR_BULK_EXTENSION_SUCCESS,
                actionTypes.COLLECT_UPCOMING_INVOICES_FOR_BULK_EXTENSION_FAILURE
            ]
        }
    }),

    COLLECT_CONTRACTS_FOR_BULK_EXTENSION:()=>({
        [CALL_API]: {
            endpoint: '/contracts',
            method: 'GET',
            backend_slug: 'garage_management',
            types: [
                actionTypes.COLLECT_CONTRACTS_FOR_BULK_EXTENSION_REQUEST,
                actionTypes.COLLECT_CONTRACTS_FOR_BULK_EXTENSION_SUCCESS,
                actionTypes.COLLECT_CONTRACTS_FOR_BULK_EXTENSION_FAILURE,
            ]
        }
    }),

    EXTEND_OVERDUE_INVOICES:(payload)=>({
        [CALL_API]: {
            endpoint: '/extend_contract_tenure',
            method: 'POST',
            body: payload,
            backend_slug: 'garage_management',
            types: [
                actionTypes.EXTEND_OVERDUE_INVOICES_REQUEST,
                actionTypes.EXTEND_OVERDUE_INVOICES_SUCCESS,
                actionTypes.EXTEND_OVERDUE_INVOICES_FAILURE,
            ]
        }
    }),

    EXTEND_UPCOMING_INSTALLMENTS:(payload)=>({
        [CALL_API]: {
            endpoint: '/extend_contract_tenure',
            method: 'POST',
            body: payload,
            backend_slug: 'garage_management',
            types: [
                actionTypes.EXTEND_UPCOMING_INSTALLMENTS_REQUEST,
                actionTypes.EXTEND_UPCOMING_INSTALLMENTS_SUCCESS,
                actionTypes.EXTEND_UPCOMING_INSTALLMENTS_FAILURE,
            ]
        }
    }),

    RETURN_TO_INITIAL_STATE_FOR_BULK_INVOICE_EXTENSION: ()=>({type: actionTypes.RETURN_TO_INITIAL_STATE_FOR_BULK_INVOICE_EXTENSION}),
    RETURN_TO_INITIAL_STATE: ()=>({type: actionTypes.RETURN_TO_INITIAL_STATE})
};
