/**
 * Constructs a comma-separated string from a list of objects based on a specified key.
 *
 * This function iterates through an array of items, extracting the value of a specified key
 * from each object and concatenating them into a single, comma-separated string.
 *
 * @param {Object[]} items - Array of objects to process.
 * @param {string} [keyUsed='pk'] - The key to retrieve values from each object in the `items` array.
 *                                  Defaults to 'pk' if no key is provided.
 * @returns {string} A comma-separated string of values from the specified key of each object in `items`.
 *
 * @example
 * // Example usage:
 * const items = [{ pk: 1 }, { pk: 2 }, { pk: 3 }];
 * const result = formulateApiRequestDataFromSelectedItems(items); // "1,2,3"
 *
 * @example
 * const items = [{ id: 'a' }, { id: 'b' }, { id: 'c' }];
 * const result = formulateApiRequestDataFromSelectedItems(items, 'id'); // "a,b,c"
 */
export const formulateApiRequestDataFromSelectedItems = (items, keyUsed='pk') => {
    return items.map(item => item[keyUsed]).join(',')
}