import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux';
import { actions as commonActions } from '../../features/commonReduxActions/actions';
import {makeStyles} from "@material-ui/styles";
import {Button, Form, Spinner} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import moment from "moment";
import {useMediaQuery} from "react-responsive";
import {SolInput, SolTextArea} from "../SolStyledComponents/components";
import Select from "react-select";
import {isValidPhoneNumber} from "libphonenumber-js";
import Stack from "@mui/material/Stack";


const BranchAddEdit = ({ submitFormData, closeForm, modalFooter, editable, branchData, zoneAreaData={}, makeApiCallForZoneArea=true, orgGuid, ...props }) => {
    const {register, errors, control, clearErrors, handleSubmit, reset, setValue, setError} = useForm();
    const [language, setLanguage] = useState("EN")

    const [divisions, setDivisions] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [subDistricts, setSubDistricts] = useState([]);

    const [zoneList, setZoneList] = useState([]);
    const [areaList, setAreaList] = useState([]);
    const [allAreas, setAllAreas] = useState([]);

    const [districtSelectionIsAlreadyCleared, setDistrictSelectionIsAlreadyCleared] = useState(false);
    const [subDistrictSelectionIsAlreadyCleared, setSubDistrictSelectionIsAlreadyCleared] = useState(false);

    const {
        COLLECT_DIVISIONS,
        COLLECT_DISTRICTS,
        COLLECT_SUB_DISTRICTS,
        COLLECT_ZONE_AREA_FOR_BRANCH,
        RETURN_TO_INITIAL_STATES_OF_BRANCH_FORM
    } = props;

    useEffect(()=>{
        setLanguage(props.language)
        COLLECT_DIVISIONS()
        if (editable && branchData && Object.keys(branchData).length > 0) {
            setValue('name', branchData['name']? branchData['name']:'')
            setValue('address_details', branchData['address_details']? branchData['address_details']:'')
            setValue('contact_person_name', branchData['contact_person_name']? branchData['contact_person_name']:'')
            setValue('contact_person_phone', branchData['contact_person_phone']? branchData['contact_person_phone']:'')
            setValue('contact_person_designation', branchData['contact_person_designation']? branchData['contact_person_designation']:'')
        }
        let zones = Object.keys(zoneAreaData)
        if (zones.length > 0) {
            zoneAreaListConstruction(zones, zoneAreaData)
        }
        return ()=>{
            RETURN_TO_INITIAL_STATES_OF_BRANCH_FORM()
        }
    }, [])

    useEffect(() => {
        if (orgGuid && makeApiCallForZoneArea) {
            // In general, when zoneAreaData is not available, we will make API call
            // That's why default value of 'makeApiCallForZoneArea' is true
            // And in real life, only during org create process API call is not needed for sure (as org with zone info is yet to be created)
            // Also, Sometimes the value of 'orgGuid' gets updated later (Ex: "Add Branch" via financier list)
            COLLECT_ZONE_AREA_FOR_BRANCH(orgGuid)
        }
    }, [orgGuid])

    const zoneAreaListConstruction = (zones, wholeZoneAreaData) => {
        let zonesForSelection = []
        let areasForSelection = []

        let defaultZoneIsSet = false
        let defaultAreaIsSet = false
        for (let i=0; i<zones.length; i++) {
            let updateAreaList = false
            let zoneOption = {
                'id': i + 1,
                'label': zones[i],
                'value': zones[i]
            }
            zonesForSelection.push(zoneOption)
            if (editable && branchData && Object.keys(branchData).length >0 && branchData.zone === zones[i]) {
                setValue('zone', zoneOption)
                defaultZoneIsSet = true
                updateAreaList = true
            }
            let areasUnderThisZone = wholeZoneAreaData[zones[i]].map((area) => {
                let areaOption = {
                    'zone_id': i + 1,
                    'label': area,
                    'value': area
                }
                if (editable && branchData && Object.keys(branchData).length >0 && branchData.area === area) {
                    setValue('area', areaOption)
                    defaultAreaIsSet = true
                    updateAreaList = true
                }
                return areaOption
            })
            areasForSelection.push(...areasUnderThisZone)
            if (updateAreaList) {
                setAreaList(areasUnderThisZone)
            }
        }
        if (editable && branchData && Object.keys(branchData).length >0 && branchData.zone && branchData.zone !== '-' && !defaultZoneIsSet) {
            // Branch's current zone is not available at the uploaded zone area csv or at returned zone/area info via API
            let currentZone = {
                'id': zonesForSelection.length + 1,
                'label': branchData.zone,
                'value': branchData.zone
            }
            zonesForSelection.push(currentZone)
            setValue('zone', currentZone)
        }
        if (editable && branchData && Object.keys(branchData).length >0 && branchData.area && branchData.area !== '-' && !defaultAreaIsSet) {
            // Branch's current area is not available at the uploaded zone area csv or at returned zone/area info via API
            let currentArea = {
                'id': 0,
                'label': branchData.area,
                'value': branchData.area
            }
            areasForSelection.push(currentArea)
            setValue('area', currentArea)
        }
        setZoneList(zonesForSelection)
        setAllAreas(areasForSelection)
    }

    const informationIsRequired = 'Above information is required!'

    useEffect(() => {
        if (props.divisions && props.divisions.length > -1) {
            const divisions = props.divisions
            setDivisions(divisions.map((item) => {
                let division = {
                    ...item,
                    value: item.id,
                    label: item.name
                }
                if (editable && branchData && Object.keys(branchData).length >0 && branchData.division === division.name) {
                    setValue('division', division)
                    COLLECT_DISTRICTS(division.value)
                }

                return division
            }))
        } else {
            setDivisions([])
        }
    }, [props.divisions])

    useEffect(() => {
        if (props.districts && props.districts.length > -1) {
            const districts = props.districts
            setDistricts(districts.map((item) => {
                let district = {
                    ...item,
                    value: item.id,
                    label: item.name
                }
                if (editable && branchData && Object.keys(branchData).length >0 && branchData.district === district.name && !districtSelectionIsAlreadyCleared) {
                    setValue('district', district)
                    COLLECT_SUB_DISTRICTS(district.value)
                }

                return district
            }))
        } else {
            setDistricts([])
        }
    }, [props.districts])

    useEffect(() => {
        if (props.subDistricts && props.subDistricts.length > -1) {
            const subDistricts = props.subDistricts
            setSubDistricts(subDistricts.map((item) => {
                let upazila = {
                    ...item,
                    value: item.id,
                    label: item.name
                }
                if (editable && branchData && Object.keys(branchData).length >0 && branchData.upazila === upazila.name && !subDistrictSelectionIsAlreadyCleared) {
                    setValue('upazila', upazila)
                }

                return upazila
            }))
        } else {
            setSubDistricts(undefined)
        }
    }, [props.subDistricts])

    useEffect(() => {
        if (props.zoneAreas && props.zoneAreas.zone_areas) {
            const zoneAreas = props.zoneAreas.zone_areas
            const zones = Object.keys(zoneAreas)
            if (zones.length > 0) {
                zoneAreaListConstruction(zones, zoneAreas)
            }
        }
    }, [props.zoneAreas])

    const saveBranch = (data) => {
        submitFormData({...branchData, ...data})
    }

    return (
        <div>
            <Form onSubmit={handleSubmit(saveBranch)}>
                <div className={'row g-3'}>
                    <div className={'col-md-6'}>
                        <Form.Group>
                            <div>
                                <Form.Label>Branch Name <span className="required text-danger">*</span></Form.Label>
                            </div>
                            <SolInput
                                name={'name'}
                                type={"text"}
                                placeholder={"Enter Branch Name..."}
                                autoComplete={"off"}
                                ref={register({
                                    required: informationIsRequired,
                                    validate: {
                                        isValidName: value => (/^[a-zA-Z0-9-'. ]+$/.test(value) || !value) || "Invalid branch name!"
                                    }
                                })}
                            />
                            {errors.name && <div className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.name.message}</div>}
                        </Form.Group>
                    </div>
                </div>
                <div className={'row g-3'}>
                    <div className={'col-md-6'}>
                        <Form.Group>
                            <div>
                                <Form.Label>House, and Road Number Details <span className="required text-danger">*</span></Form.Label>
                            </div>
                            <SolTextArea
                                name={"address_details"}
                                rows={"3"}
                                placeholder={"Address details..."}
                                autoComplete={"off"}
                                ref={register({
                                    required: informationIsRequired
                                })}
                            />
                            {errors.address_details && <div className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.address_details.message}</div>}
                        </Form.Group>
                    </div>
                </div>
                <div className={'row g-3'}>
                    <div className={'col-md-4'}>
                        <Form.Group>
                            <div>
                                <Form.Label>Division <span className="required text-danger">*</span></Form.Label>
                            </div>
                            <Controller
                                control={control}
                                name={"division"}
                                defaultValue={""}
                                rules={{ required: informationIsRequired }}
                                render={( { onChange, onBlur, value, name, ref },
                                          { invalid, isTouched, isDirty }) => (
                                    <Select
                                        placeholder={'Select Division'}
                                        classNamePrefix="react-select-sol-style"
                                        isDisabled={props.collectingDivisions}
                                        isLoading={props.collectingDivisions}
                                        maxMenuHeight={200}
                                        value={value}
                                        isClearable={true}
                                        control={control}
                                        inputRef={ref}
                                        options={divisions}
                                        isSearchable={true}
                                        noOptionsMessage={() => "No divisions"}
                                        onChange={(selected, {action}) => {
                                            onChange(selected)
                                            setValue('district', '')
                                            setDistrictSelectionIsAlreadyCleared(true)
                                            setDistricts([])

                                            setValue('upazila', '')
                                            setSubDistrictSelectionIsAlreadyCleared(true)
                                            setSubDistricts([])
                                            if (selected) {
                                                COLLECT_DISTRICTS(selected.value)
                                            }
                                        }}
                                    />
                                )}
                            />
                            {errors.division && <div className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.division.message}</div>}
                        </Form.Group>
                    </div>
                    <div className={'col-md-4'}>
                        <Form.Group>
                            <div>
                                <Form.Label>District <span className="required text-danger">*</span></Form.Label>
                            </div>
                            <Controller
                                control={control}
                                name={"district"}
                                defaultValue={""}
                                rules={{ required: informationIsRequired }}
                                render={( { onChange, onBlur, value, name, ref },
                                          { invalid, isTouched, isDirty }) => (
                                    <Select
                                        placeholder={'Select District'}
                                        classNamePrefix="react-select-sol-style"
                                        isDisabled={props.collectingDistricts}
                                        isLoading={props.collectingDistricts}
                                        maxMenuHeight={200}
                                        value={value}
                                        isClearable={true}
                                        control={control}
                                        inputRef={ref}
                                        options={districts}
                                        isSearchable={true}
                                        noOptionsMessage={() => "No districts"}
                                        onChange={(selected, {action}) => {
                                            onChange(selected)
                                            setValue('upazila', '')
                                            setSubDistrictSelectionIsAlreadyCleared(true)
                                            setSubDistricts([])
                                            if (selected) {
                                                COLLECT_SUB_DISTRICTS(selected.value)
                                            }
                                        }}
                                    />
                                )}
                            />
                            {errors.district && <div className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.district.message}</div>}
                        </Form.Group>
                    </div>
                    <div className={'col-md-4'}>
                        <Form.Group>
                            <div>
                                <Form.Label>Sub District / City Corporation <span className="required text-danger">*</span></Form.Label>
                            </div>
                            <Controller
                                control={control}
                                name={"upazila"}
                                defaultValue={""}
                                rules={{ required: informationIsRequired }}
                                render={( { onChange, onBlur, value, name, ref },
                                          { invalid, isTouched, isDirty }) => (
                                    <Select
                                        placeholder={'Select Sub District'}
                                        classNamePrefix="react-select-sol-style"
                                        isDisabled={props.collectingSubDistricts}
                                        isLoading={props.collectingSubDistricts}
                                        maxMenuHeight={200}
                                        value={value}
                                        isClearable={true}
                                        control={control}
                                        inputRef={ref}
                                        options={subDistricts}
                                        isSearchable={true}
                                        noOptionsMessage={() => "No sub-districts"}
                                        onChange={(selected, {action}) => {
                                            onChange(selected)
                                        }}
                                    />
                                )}
                            />
                            {errors.upazila && <div className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.upazila.message}</div>}
                        </Form.Group>
                    </div>
                </div>
                <div className={'row g-3'}>
                    <div className={'col-md-4'}>
                        <Form.Group>
                            <div>
                                <Form.Label>Zone / Region</Form.Label>
                            </div>
                            <Controller
                                control={control}
                                name={"zone"}
                                defaultValue={""}
                                render={( { onChange, onBlur, value, name, ref },
                                          { invalid, isTouched, isDirty }) => (
                                    <Select
                                        placeholder={'Select a Zone'}
                                        classNamePrefix="react-select-sol-style"
                                        isDisabled={props.zoneAreaCollectionInProgress}
                                        isLoading={props.zoneAreaCollectionInProgress}
                                        maxMenuHeight={200}
                                        value={value}
                                        isClearable={true}
                                        control={control}
                                        inputRef={ref}
                                        options={zoneList}
                                        isSearchable={true}
                                        noOptionsMessage={() => "No zones"}
                                        onChange={(selected, {action}) => {
                                            if (action === "clear") {
                                                setValue('area', '')
                                                setAreaList([])
                                            }
                                            onChange(selected)
                                            if (selected) {
                                                setValue('area', '')
                                                setAreaList(allAreas.filter(item => item.zone_id === selected.id))
                                            }
                                        }}
                                    />
                                )}
                            />
                            {errors.zone && <div className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.zone.message}</div>}
                        </Form.Group>
                    </div>
                    <div className={'col-md-4'}>
                        <Form.Group>
                            <div>
                                <Form.Label>Area</Form.Label>
                            </div>
                            <Controller
                                control={control}
                                name={"area"}
                                defaultValue={""}
                                render={( { onChange, onBlur, value, name, ref },
                                          { invalid, isTouched, isDirty }) => (
                                    <Select
                                        placeholder={'Select an area'}
                                        classNamePrefix="react-select-sol-style"
                                        isDisabled={props.zoneAreaCollectionInProgress}
                                        isLoading={props.zoneAreaCollectionInProgress}
                                        maxMenuHeight={200}
                                        value={value}
                                        isClearable={true}
                                        control={control}
                                        inputRef={ref}
                                        options={areaList}
                                        isSearchable={true}
                                        noOptionsMessage={() => "No areas"}
                                        onChange={(selected, {action}) => {
                                            onChange(selected)
                                        }}
                                    />
                                )}
                            />
                            {errors.area && <div className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.area.message}</div>}
                        </Form.Group>
                    </div>
                </div>
                <div className={'row g-3'}>
                    <div className={'col-md-6'}>
                        <Form.Group>
                            <div>
                                <Form.Label>Contact Person / Branch Manager / Area Manager <span className="required text-danger">*</span></Form.Label>
                            </div>
                            <SolInput
                                name={'contact_person_name'}
                                type={"text"}
                                placeholder={"Contact Person's Name..."}
                                autoComplete={"off"}
                                ref={register({
                                    required: informationIsRequired,
                                    validate: {
                                        isValidName: value => (/^[a-zA-Z0-9-'. ]+$/.test(value) || !value) || "Invalid name!"
                                    }
                                })}
                            />
                            {errors.contact_person_name && <div className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.contact_person_name.message}</div>}
                        </Form.Group>
                    </div>
                </div>
                <div className={'row g-3'}>
                    <div className={'col-md-6'}>
                        <Form.Group>
                            <div>
                                <Form.Label>Contact Person's Mobile Number <span className="required text-danger">*</span></Form.Label>
                            </div>
                            <SolInput
                                name={'contact_person_phone'}
                                type={"text"}
                                placeholder={"Contact Person's Mobile Number..."}
                                autoComplete={"off"}
                                ref={register({
                                    required: informationIsRequired,
                                    validate: {
                                        isValidPhoneNumber: value => (isValidPhoneNumber(value, 'BD') || !value) || "Invalid phone!"
                                    }
                                })}
                            />
                            {errors.contact_person_phone && <div className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.contact_person_phone.message}</div>}
                        </Form.Group>
                    </div>
                </div>
                <div className={'row g-3'}>
                    <div className={'col-md-6'}>
                        <Form.Group>
                            <div>
                                <Form.Label>Contact Person's Designation <span className="required text-danger">*</span></Form.Label>
                            </div>
                            <SolInput
                                name={'contact_person_designation'}
                                type={"text"}
                                placeholder={"Contact Person's Designation..."}
                                autoComplete={"off"}
                                ref={register({
                                    required: informationIsRequired,
                                    validate: {
                                        isValidName: value => (/^[a-zA-Z-. ]+$/.test(value) || !value) || "Invalid designation!"
                                    }
                                })}
                            />
                            {errors.contact_person_designation && <div className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.contact_person_designation.message}</div>}
                        </Form.Group>
                    </div>
                </div>
                <hr/>
                <div className={'row mt-3'}>
                    <div className={"col-md-12"}>
                        {modalFooter? <>
                            <Stack
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="flex-start"
                                spacing={2}
                            >
                                <Button style={{
                                    backgroundColor: '#6c757d',
                                    border: 'none'
                                }} size={'lg'} onClick={closeForm}>
                                    Cancel
                                </Button>
                                <Button variant="warning" size={'lg'} type="submit"
                                        disabled={props.creatingBranches}>
                                    {props.creatingBranches? <><Spinner animation={'border'} size={'sm'} variant={'light'}/>&nbsp;</>:null}Save
                                </Button>
                            </Stack>
                        </>:<>
                            <Button variant={"dark"} size={'md'}
                                    onClick={closeForm}
                                    style={{ backgroundColor: '#8C8C9B', outline: '#8C8C9B', border: '#8C8C9B' }}
                                    disabled={props.creatingBranches}>
                                <i className='fa fa-times' aria-hidden='true'/>&nbsp;{language === "EN" ? "Cancel" : "বাতিল করুন"}
                            </Button>
                            <Button variant={"warning"} type={"submit"} size={"md"} className={"ml-3"}
                                    disabled={props.creatingBranches}>
                                {props.creatingBranches? <><Spinner animation={'border'} size={'sm'} variant={'light'}/></> : null}
                                <i className='fa fa-paper-plane' aria-hidden='true' />&nbsp;{language === "EN"? "Save" : "সংরক্ষণ করুন"}
                            </Button>
                        </>}
                    </div>
                </div>
            </Form>
        </div>
    );
};

BranchAddEdit.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        divisions: state.commonReducer.divisions,
        collectingDivisions: state.commonReducer.collectingDivisions,
        districts: state.commonReducer.districts,
        collectingDistricts: state.commonReducer.collectingDistricts,
        subDistricts: state.commonReducer.subDistricts,
        collectingSubDistricts: state.commonReducer.collectingSubDistricts,
        creatingBranches: state.commonReducer.creatingBranches,
        zoneAreas: state.commonReducer.zoneAreas,
        zoneAreaCollectionInProgress: state.commonReducer.zoneAreaCollectionInProgress,
    }
}

export default connect(mapStateToProps, { ...commonActions })(BranchAddEdit)