import React, { useEffect } from 'react';
import { useState } from 'react';
import {connect} from 'react-redux';
import {contractStatuses} from "../components";
import { withRoles } from "../../../../router/SecuredRoute";
import {useSubheader} from "../../../../../_metronic/layout";
import { LoadingSpinner } from '../../../../components';
import { actions } from '../../actions';
import { actions as commonActions } from '../../../commonReduxActions/actions';
import {makeStyles} from "@material-ui/styles";
import FilePresentIcon from '@mui/icons-material/FilePresent';
import {checkAuthorization} from "../../../auth/authorization";
import {Button} from "react-bootstrap";
import '../customStylesForContractManagement.css'
import Stack from "@mui/material/Stack";
import {getServiceName} from "../../../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../../../utils/roleRelatedValues/actionNames";
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import ContractSummary from "./sections/contractSummary"
import AdditionalInfoWithFeesAndCharges from "./sections/additionalInfoWithFees"
import RepaymentSchedule from "./sections/repaymentSchedule"
import OperationalLog from "./sections/operationalLog"
import NomineeInfo from "./sections/nomineeInfo"
import Settings from "./sections/settings"
import ReleaseSmartBattery from "./operations/releaseSmartBattery"


const useStylesIconButton = makeStyles({
    root: {
        marginTop: '0',
        paddingTop: '4px',
    },
});

const ContractDetails = ({ data, ...props }) => {
    const [language, setLanguage] = useState("EN");
    const [fullLoanDetails, setFullLoanDetails] = useState(undefined);
    const [loanID, setLoanID] = useState('');
    const [loanType, setLoanType] = useState('');
    const [loanDocName, setLoanDocName] = useState('')
    const [loanDocLink, setLoanDocLink] = useState('')
    const [garageName, setGarageName] = useState('')
    const [garageGuid, setGarageGuid] = useState('')
    const [borrowerName, setBorrowerName] = useState('')

    const [keyCountForSettingsComponent, setKeyCountForSettingsComponent] = useState(0)
    const [canEdit, setCanEdit] = useState(false)
    const [canReschedule, setCanReschedule] = useState(false)

    const [canPause, setCanPause] = useState(false)
    const [canResume, setCanResume] = useState(false)

    const [canUpdateInstallment, setCanUpdateInstallment] = useState(false)

    const [canUpdateStatus, setCanUpdateStatus] = useState(false)

    const garageServiceName = getServiceName('garageService')
    const transactionServiceName = getServiceName('transactionService')
    const contractManagementFeatureName = getFeatureName('contractManagementFeature')
    const invoiceFeatureName = getFeatureName('invoiceFeature')
    const updateActionName = getActionName('updateAction')
    const installmentUpdateActionName = getActionName('contractInstallmentUpdateAction')
    const rescheduleInvoiceActionName = getActionName('contractRescheduleInvoiceAction')
    const pauseActionName = getActionName('contractPauseAction')
    const resumeActionName = getActionName('contractResumeAction')
    const contractStatusUpdateActionName = getActionName('contractStatusUpdateAction')

    const classes = useStylesIconButton();
    const subheader = useSubheader();

    const {
        COLLECT_LOAN_DETAILS,
        GET_GARAGE_LIST,
        RETURN_TO_INITIAL_STATE
    } = props;

    useEffect(() => {
        setLanguage(props.language)

        setLoanID(props.match.params.contract_id)
        COLLECT_LOAN_DETAILS(props.match.params.contract_id)
        GET_GARAGE_LIST()

        setCanEdit(checkAuthorization(props.authorization, garageServiceName, contractManagementFeatureName, [updateActionName]))
        setCanUpdateInstallment(checkAuthorization(props.authorization, garageServiceName, contractManagementFeatureName, [installmentUpdateActionName]))
        setCanReschedule(checkAuthorization(props.authorization, transactionServiceName, invoiceFeatureName, [rescheduleInvoiceActionName]))
        setCanPause(checkAuthorization(props.authorization, garageServiceName, contractManagementFeatureName, [pauseActionName]))
        setCanResume(checkAuthorization(props.authorization, garageServiceName, contractManagementFeatureName, [resumeActionName]))
        setCanUpdateStatus(checkAuthorization(props.authorization, garageServiceName, contractManagementFeatureName, [contractStatusUpdateActionName]))

        subheader.setBreadCrumbComponent(null)
        subheader.setActionButtons(null)

        return ()=>{
            RETURN_TO_INITIAL_STATE()
        }
    }, [])

    useEffect(() => {
        if (props.loanDetails) {
            const loanDetails = props.loanDetails
            setFullLoanDetails(loanDetails)
            let loanID = loanDetails.contract_id
            let loanType = loanDetails.contract_type // We haven't checked for the value at the 'lease_type' here as
            // this 'loanType' variable is only going to be used only for showing repayment method and amount (not the actual contract type)
            // - Noor Reza, 8th December, 2024
            let garageName = loanDetails.device_details?.garage_name
            setLoanType(loanType)
            setGarageName(garageName)
            setGarageGuid(loanDetails.device_details?.garage_guid)

            let statusesForSettingsMenu = ['active', 'paused']
            let contractIdText = 'Contract #' + loanID
            let statusObj = contractStatuses.find(item => item.value === loanDetails.status)
            subheader.setBreadCrumbComponent(
                <>
                    <h5 className="text-dark font-weight-bold my-2 mr-5">
                        <a onClick={() => {props.history.push({pathname: "/contract-management/list"})}}>
                            Contract Management</a> / {contractIdText}{shouldSettingsMenuBecomeAccessible(loanDetails, statusesForSettingsMenu)?
                        <> / <span style={{ color: statusObj['style']['color']}}>{statusObj['label']}</span></>:<>
                            {statusObj && statusObj['value'] === 'closed'? <>
                                {loanDetails.battery_state === 'Released'? <> / <span style={{ color: '#0B6E4F' }}>Battery is released</span></>:
                                    <> / <span style={{ color: '#CB152B' }}>Battery is not released</span></>}
                            </>:''}
                        </>}
                    </h5>
                </>
            )

            setBorrowerName(loanDetails.borrower_name)

            setLoanDocName(loanDetails.doc_file_name? loanDetails.doc_file_name:'')
            setLoanDocLink(loanDetails.doc_file_link? loanDetails.doc_file_link:'')

            subheader.setActionButtons(<>
                <div className="d-flex align-item-center justify-content-end">
                    <Stack
                        direction="column"
                        justifyContent="flex-end"
                        alignItems="flex-end"
                        spacing={0.5}
                        className={"mt-2"}
                    >
                        {shouldSettingsMenuBecomeAccessible(loanDetails, statusesForSettingsMenu)? <>
                            <Settings key={keyCountForSettingsComponent}
                                      contractDetails={props.loanDetails}
                                      userCanEdit={canEdit}
                                      userCanReschedule={canReschedule}
                                      userCanUpdateInstallment={canUpdateInstallment}
                                      userCanPause={canPause} userCanResume={canResume}
                                      userCanUpdateStatus={canUpdateStatus}/>
                        </>:<>
                            {statusObj['value'] === 'closed' && props.loanDetails.battery_state !== 'Released' && canUpdateStatus? <>
                                <ReleaseSmartBattery contractDetails={props.loanDetails}/>
                            </>:<>
                                <Button size={'sm'} type="button" style={{
                                    backgroundColor: statusObj['style']['color'],
                                    borderColor: statusObj['style']['color'],
                                }} classes={classes} className={'ml-1'}>
                                    {statusObj['label']}
                                </Button>
                            </>}
                        </>}
                        {statusesForSettingsMenu.includes(loanDetails.status)? <>
                            <div className="small mb-2">Created By {loanDetails.created_by_name}, {loanDetails.organization_details.name}</div>
                        </>:<></>}
                    </Stack>
                </div>
            </>)
            setKeyCountForSettingsComponent(keyCountForSettingsComponent + 1)
        } else {
            setFullLoanDetails(undefined)
        }
    }, [props.loanDetails])

    // Saving latest garage information
    useEffect(() => {
        if (props.garages && props.garages.length > -1) {
            const garages = props.garages;
            const filteredData = garages.filter(garage => garage.pk === garageGuid)
            if (filteredData.length > 0) {
                if (filteredData[0].name) {
                    setGarageName(filteredData[0].name)
                }
                if (filteredData[0].garage_owner) {
                    setBorrowerName(filteredData[0].garage_owner)
                }
            }
        }
    }, [props.garages, fullLoanDetails])

    // Table header styling
    const tableHeaderLeftAlignedColumnStyling = {
        fontWeight: 500,
        fontSize: "1.1rem",
        color: "#8C8C9B",
        // paddingLeft: "0px",
        textAlign: "left",
        // width: 'calc(6.16667px)',
        boxSizing: "borderBox"
    }

    const tableHeaderRightAlignedColumnStyling = {
        fontWeight: 500,
        fontSize: "1.1rem",
        color: "#8C8C9B",
        paddingLRight: "0px",
        textAlign: "right",
        boxSizing: "borderBox"
    }

    // Table cell related styling
    const tableLeftAlignedContentStyling = {
        color: "inherit",
        // width: calc(112.5px),
        boxSizing: "border-box",
        fontSize: "inherit",
        fontFamily: "inherit",
        fontWeight: "inherit",
        // paddingLeft: "0px",
        // width: 'calc(47.333px)',
        textAlign: "left",
        // whiteSpace: 'nowrap'
    }

    const tableRightAlignedContentStyling = {
        color: "inherit",
        // width: calc(112.5px),
        boxSizing: "border-box",
        fontSize: "inherit",
        fontFamily: "inherit",
        fontWeight: "inherit",
        // paddingRight: "0px",
        // width: 'calc(47.333px)',
        textAlign: "right",
        // whiteSpace: 'nowrap'
    }

    const shouldSettingsMenuBecomeAccessible = (loanDetails, validStatusesForSettings) => {
        if ((canEdit || canReschedule || canUpdateInstallment || canPause || canResume || canUpdateStatus) &&
            validStatusesForSettings.includes(loanDetails?.status)) {
            return true
        }
        return false
    }

    return (
        <ContentWrapper showCardHeader={false} showBackButton={false} isLoading={props.isLoading} serverError={false}
                        permissionDenied={false} pageTitle={'Loan Details'} statusCode={props.statusCode}>
            {!props.loanDetailsCollectionInProgress && fullLoanDetails? <>
                {Object.keys(fullLoanDetails).length > 0? <>
                    {/* Top summary table */}
                    <ContractSummary contractDetails={fullLoanDetails} garageName={garageName}
                                     borrowerName={borrowerName} tableHeaderLeftAlignedColumnStyling={tableHeaderLeftAlignedColumnStyling}
                                     tableHeaderRightAlignedColumnStyling={tableHeaderRightAlignedColumnStyling}
                                     tableLeftAlignedContentStyling={tableLeftAlignedContentStyling}
                                     tableRightAlignedContentStyling={tableRightAlignedContentStyling}/>

                    {/* "Fees and Charges" and "Additional info" tables */}
                    <AdditionalInfoWithFeesAndCharges contractDetails={fullLoanDetails}
                                                      tableHeaderLeftAlignedColumnStyling={tableHeaderLeftAlignedColumnStyling}
                                                      tableLeftAlignedContentStyling={tableLeftAlignedContentStyling}/>

                    {/* "Nominee" table */}
                    <NomineeInfo contractDetails={fullLoanDetails}
                                 tableHeaderLeftAlignedColumnStyling={tableHeaderLeftAlignedColumnStyling}
                                 tableLeftAlignedContentStyling={tableLeftAlignedContentStyling}/>

                    {/* Show "Repayment Schedule" section only when loan is lease based */}
                    {loanType === 'rent'? null:<>
                        <RepaymentSchedule contractDetails={fullLoanDetails}/>
                    </>}

                    {/* "Operational Logs" table */}
                    <OperationalLog contractDetails={fullLoanDetails}/>

                    {/* Uploaded document during contract creation */}
                    {loanDocName? <>
                        <div className={'row mt-10'}>
                            <div className={'col-md-1'}></div>
                            <div className={'col-md-10'}>
                                <h6><strong>Documents</strong></h6>
                            </div>
                            <div className={'col-md-1'}></div>
                        </div>
                        <div className={'row mt-5'}>
                            <div className={'col-md-1'}></div>
                            <div className={'col-md-11'} style={{display: "flex", flexDirection: "row"}}>
                                {loanDocLink? <>
                                    <a href={loanDocLink} download={loanDocName}><FilePresentIcon/> <strong>{loanDocName}</strong></a>
                                </>:<>
                                    <FilePresentIcon/> <h6><strong>{loanDocName}</strong></h6>
                                </>}
                            </div>
                        </div>
                    </>:null}
                </>:<>
                    <div className={'row'}>
                        <div className={'col-md-12'}>
                            <p className={'text-center'}><h1>No details available</h1></p>
                        </div>
                    </div>
                </>}
            </>:<LoadingSpinner
                loadingSubText={'Collecting details of the contract ' + loanID + ' ..'} language={language}/>}
        </ContentWrapper>
    )
}

ContractDetails.propTypes = {}

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        loanDetails: state.contractManagementReducer.loanDetails,
        loanDetailsCollectionInProgress: state.contractManagementReducer.loanDetailsCollectionInProgress,
        garages: state.commonReducer.garages
    }
}

export default connect(mapStateToProps, { ...actions, ...commonActions })(withRoles(ContractDetails));