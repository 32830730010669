import React from "react";
import styled from "styled-components";
import moment from "moment";


export const DataTableContainer = styled.div`
  padding: 4rem 0 0 0;
  min-height: 25rem;
  height: auto;
`


export const requestCycle = {
    success: 'success',
    pending: 'pending',
    failed: 'failed',
}


/**
 * Calculates the duration between two times and returns a formatted string
 * in the format of "Xh and Ym" or "Xm", depending on the duration.
 *
 * @param {string|Date|moment.Moment} startTime - The start time of the rent.
 * @param {string|Date|moment.Moment} endTime - The end time of the rent.
 * @returns {string} The formatted duration as a string (e.g., "2h and 30m" or "30m").
 */
export const calculateRentDuration = (startTime, endTime) => {
    // Parse start and end times into moment objects
    let rentStartTimeMomentObj = moment(startTime);
    let rentExpireTimeMomentObj = moment(endTime);

    // Calculate the total duration in minutes
    let rentDurationInMinutes = rentExpireTimeMomentObj.diff(rentStartTimeMomentObj, 'minutes');
    let rentDurationInHours = Math.floor(rentDurationInMinutes / 60);
    let rentDurationInRemainingMinutes = rentDurationInMinutes % 60;

    let result = '';

    // Append hours if greater than 0
    if (rentDurationInHours > 0) {
        result += `${rentDurationInHours}h`;
    }

    // Append minutes if greater than 0
    if (rentDurationInRemainingMinutes > 0) {
        if (result) result += ' and '; // Add "and" if hours are present
        result += `${rentDurationInRemainingMinutes}m`;
    }

    // If no hours or minutes, return total minutes
    if (!result) {
        result = `${rentDurationInMinutes}m`;
    }

    return result;
}